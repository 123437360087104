<template>
  <div class="employee-channel-list">
    <div class="employee-channel-list__heading">
      {{ heading }}
    </div>
    <div class="employee-channel-list__actions">
      <div class="employee-channel-list__tabs">
        <div
          class="employee-channel-list__select"
          :class="{
          'employee-channel-list__select--active': departmentSelected === DepartmentKeys.Sale,
        }"
          @click="setDepartment(DepartmentKeys.Sale)"
        >
          {{ DepartmentKeys.Sale }}
        </div>
        <div
          class="employee-channel-list__select"
          :class="{
          'employee-channel-list__select--active': departmentSelected === DepartmentKeys.Mechanic,
        }"
          @click="setDepartment(DepartmentKeys.Mechanic)"
        >
          {{ DepartmentKeys.Mechanic }}
        </div>
        <div
        v-if="displayDamage"
          class="employee-channel-list__select"
          :class="{
          'employee-channel-list__select--active': departmentSelected === DepartmentKeys.Damage,
        }"
          @click="setDepartment(DepartmentKeys.Damage)"
        >
          {{ DepartmentKeys.Damage }}
        </div>
      </div>
      <label for="employeeSearch" class="employee-channel-list__input">
        <font-awesome-icon v-if="loading" :icon="faSpinnerThird" :spin="true"></font-awesome-icon>
        <font-awesome-icon v-else :icon="faSearch"></font-awesome-icon>

        <input
          v-model="searchString"
          type="text"
          name="employeeSearch"
          id="employeeSearch"
          :placeholder="lang.Contact.EmployeeListSearchPlaceholder"
        />
      </label>
    </div>
    <div class="employee-channel-list__quantity-text">
      {{ quantityText }}
    </div>
    <div class="employee-channel-list__list">
      <a
        class="employee-channel-list__item"
        v-for="employee in employeeList"
        :data-modal-source="!employee.digitalBusinessCard && 'ajax'"
        :data-target="!employee.digitalBusinessCard && employee.modalUrl"
        :href="employee.digitalBusinessCard && employee.permalink"
        :target="employee.digitalBusinessCard && '_blank'"
      >
        <div class="employee-channel-list__item__image">
          <img :src="employee.image" />
        </div>
        <div class="employee-channel-list__item__content">
          <div class="employee-channel-list__item__name">
            {{ employee.fullName }}
          </div>
          <div class="employee-channel-list__item__title">
            {{ employee.title }}
          </div>
        </div>
      </a>
    </div>
    <div class="employee-channel-list__pagination-container">
      <div class="employee-channel-list__pagination-text">
        {{ paginationQuantityText }}
      </div>
      <div class="employee-channel-list__pagination-bar">
        <div class="employee-channel-list__pagination-bar__background"/>
        <div class="employee-channel-list__pagination-bar__active" :style="`width: ${barWidth}%;`" />
      </div>
      <div v-if="nextPage" class="link link--icon shaded-expand__btn link-toggler-click" @click="onNext">
            <span class="shaded-expand__btn--expand">{{ lang.Contact.QuantityPaginationCta }}</span>
            <i class="fal fa-angle-down"></i>
        </div>
    </div>
  </div>
</template>

<script>
import I3DocEmployee from "../../models/I3DocEmployees";
import ChannelQuery from "../../models/ChannelQuery";
import I3QueryParams from "../../constants/I3QueryParams";
import ChannelEvents from "../../constants/ChannelEvents";
import debounce from "debounce";
import Axios from "../../axios";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faSpinnerThird, faSearch } from '@fortawesome/pro-regular-svg-icons';
import Cookies from "js-cookie";

const DepartmentKeys = {
  Sale: 'Försäljning',
  Mechanic: 'Verkstad',
  Damage: "Skadeverkstad",
}
export default {
  props: {
    channel: Object,
    lang: Object,
  },
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      documents: [],
      hits: 0,
      departmentSelected: DepartmentKeys.Sale,
      searchString: '',
      currentLocation: '',
      DepartmentKeys,
      query: null,
      loading: false,
      faSpinnerThird,
      faSearch,
      nextPage: null,
    };
  },
  watch: {
    searchString() {
      this.loading = true;
      this.searchDebounced();
    }
  },
  computed: {
    displayDamage() {
      var site = Cookies.get("site");
      if (this.currentLocation === 'Jönköping' || this.currentLocation === 'Kalmar' || (this.currentLocation === 'Värnamo' && site !== 'camping'))
      {
        return true;
      }
      return false;

    },
    employeeList() {
      return this.documents.map(doc => new I3DocEmployee(doc));
    },
    heading() {
      var site = Cookies.get("site");
      return this.lang.Contact.EmployeeListHeadingPrefix.replace('{{location}}', this.currentLocation).replace('Bil', site === "camping" ? 'Fritid': 'Bil');
    },
    quantityText() {
      return this.lang.Contact.QuantityText.replace('{{totalQuantity}}', this.hits);
    },
    paginationQuantityText() {
      return this.lang.Contact.QuantityPaginationText.replace('{{currentQuantity}}', this.documents.length).replace('{{totalQuantity}}', this.hits);
    },
    barWidth() {
      return this.documents.length / this.hits * 100;
    }
  },
  mounted() {
    this.channel.one(ChannelEvents.QuerySuccess, this.oneQuerySuccess);
    this.searchDebounced = debounce(this.search.bind(this), 500);
  },
methods: {
    async oneQuerySuccess() {
      const { params } = this.channel.lastQuery;

      this.currentLocation = params?.location;
      this.searchString = params?.q && params.q !== '*' ? params.q : '';
      this.departmentSelected = params?.department || DepartmentKeys.Sale;

      this.query = new ChannelQuery(this.channel.lastQuery);

      await this.executeLocalQuery(this.query)
    },
    onNext() {
      if (this.nextPage) {
        this.executeLocalQuery(this.nextPage, true)
      }
    },
    search() {
      const str = this.searchString;

      if (str) {
        this.query.setParam(I3QueryParams.FreeText, str);
      } else {
        this.query.setParam(I3QueryParams.FreeText, '*');
      }
      this.executeLocalQuery(this.query)
    },
    async setDepartment(department) {
      this.loading = true;
      this.query.setParam(I3QueryParams.Department, department);
      await this.executeLocalQuery(this.query);
      this.departmentSelected = department;
    },
    async executeLocalQuery(query, isLoadingMore) {
      try {
        this.loading = true;

        const res = await Axios.get(`${this.channel.url}?${query}`);

        if (isLoadingMore) {
          this.documents = [...this.documents, ...res.data.documents.documents];
        } else {
          this.documents = res.data.documents.documents;
        }
        this.hits = res.data.documents.numberOfHits;
        this.nextPage = res.data.documents.pagination.nextPage?.query || null;
      } catch (e) {
        console.warn(e);
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>
