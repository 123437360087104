import '../Styles/site.scss';
import './bugsnag';
import './polyfills';
import './font-awesome';
import './vendor-globals';
import './vue-setup';
import '@holmgrensbil/wdk';
import smoothscroll from 'smoothscroll-polyfill';

import Router from './utils/router';
import common from './routes/common';
import campaign_list_page from './routes/campaign-list-page';
import campaign_vehicle_list from './routes/campaign-vehicle-list';
import accessories_list_page from './routes/accessories-list-page';
import vehicle_list_page from './routes/vehicle-list-page';
import vehicle_page from './routes/vehicle-page';
import my_pages_page from './routes/my-pages-page';
import blog_page from './routes/blog-page';
import bloglist_page from './routes/bloglist-page';
import campaign_configurator from './routes/campaign-configurator';
import contact_page from './routes/contact-page';
import department_page from './routes/department-page';
import error_page from './routes/error-page';
import trade_in_page from './routes/trade-in-page';
import service_booking_page from './routes/service-booking-page';
import service_agreement_page from './routes/service-agreement-page';
import campaign_rollup from './routes/campaign-rollup';
import start_page from './routes/start-page';
import ecom_landing_page from './routes/ecom-landing-page';
import ecom_list_page from './routes/ecom-list-page';
import ecom_start_page from './routes/ecom-start-page';
import ecom_checkout from './routes/ecom-checkout';
import ecom_product_page from './routes/ecom-product-page';
import ecom_thankyou from './routes/ecom-thank-you-page';
import damage_report_page from './routes/damage-report-page';
import tyre_order_page from './routes/tyre-order-page';
import service_booking_page_v2 from './routes/service-booking-page-v2';
import tyre_booking_page from './routes/tyre-booking';
import spare_parts_page from './routes/spare-parts';
import glass_repair_page from './routes/glass-repair';
import ac_cleaning_page from './routes/ac-cleaning';
import campaign_tyre from './routes/campaign-tyre';
import my_vehicle_estimation from './routes/my-vehicle-estimation';
import employee_card_page from './routes/employee-card-page';
import opening_hours_print from './routes/opening-hours-print';
import rim_repair_page from './routes/rim-repair';
import service_control_page from './routes/service-control';
smoothscroll.polyfill();

const routes = {
  common,
  blog_page,
  bloglist_page,
  campaign_list_page,
  campaign_vehicle_list,
  vehicle_list_page,
  accessories_list_page,
  vehicle_page,
  campaign_configurator,
  contact_page,
  error_page,
  trade_in_page,
  service_booking_page,
  service_agreement_page,
  campaign_rollup,
  department_page,
  start_page,
  ecom_landing_page,
  ecom_list_page,
  ecom_start_page,
  ecom_checkout,
  accessories_checkout: ecom_checkout,
  ecom_product_page,
  ecom_thankyou,
  damage_report_page,
  tyre_order_page,
  service_booking_page_v2,
  tyre_booking_page,
  glass_repair_page,
  ac_cleaning_page,
  campaign_tyre,
  my_vehicle_estimation,
  employee_card_page,
  opening_hours_print,
  spare_parts_page,
  my_pages_page,
  rim_repair_page,
  service_control_page,
};

// Ensures that the callback will always run
if (document.readyState === 'complete' || document.readyState === 'loaded') {
  new Router(routes).loadEvents();
} else {
  document.addEventListener('DOMContentLoaded', () => new Router(routes).loadEvents());
}
