<template>
  <div class="releasing-block" v-if="releasingData.id">
    <h2>Personligt erbjudande för din</h2>
    <h2> {{ releasingData.marke }} {{ releasingData.modell }} - {{ releasingData.id }}
    </h2>
    <div class="releasing-block__intro">
      <p>Vi har tagit fram ett personligt erbjudande som gäller endast dig som idag kör {{ releasingData.id }}. Ett
        unikt erbjudande där du kan välja att förlänga din privatleasing med fördelaktiga villkor eller att köpa ut din
        bil till ett extra förmånligt pris.</p>
      <p class="text_danger h4">Erbjudandet gäller t.o.m. {{ releasingData.offerEndDate.split('T')[0] }}</p>
    </div>

    <div class="releasing-block__body">
      <div class="releasing-block__section">
        <div class="releasing-block__section-header">
          <h4>Köp din {{ releasingData.id }} för endast</h4>
          <div class="releasing-block__offer h1">
            {{ releasingData.avbetalningRatt }} kr/mån*
          </div>
          <p>Ditt personliga pris {{ releasingData.forsaljningRatt }} kr</p>
        </div>
        <div class="releasing-block__section-footer">
          <h3>Avbetalning</h3>
          <table>
            <tr>
              <td><strong>Ny månadskostnad</strong></td>
              <td><strong>{{ releasingData.avbetalningRatt }} kr/mån*</strong></td>
            </tr>
            <tr>
              <td>Avtalsperiod</td>
              <td>{{ releasingData.avbetAvtalsPeriod }} mån</td>
            </tr>
            <tr>
              <td>Ränta</td>
              <td>{{ releasingData.avbetRantaRatt }}</td>
            </tr>
            <tr>
              <td>Kontantinsats kr</td>
              <td>{{ releasingData.kontantinsatsRatt }} kr</td>
            </tr>
            <tr>
              <td>Ditt personliga pris</td>
              <td>{{ releasingData.forsaljningRatt }} kr</td>
            </tr>
          </table>

          <div class="form-text">* Räntan är rörlig och kan komma att påverkas både upp och ned. Priset är räknat på 20%
            kontantinsats och {{ releasingData.avbetRVRatt }} restvärde.</div>
        </div>
      </div>
      <div class="releasing-block__section">
        <div class="releasing-block__section-header">
          <h4>Förläng din privatleasing för endast</h4>
          <div class="releasing-block__offer h1">
            {{ releasingData.releasingRatt }} kr/mån**
          </div>
          <p>Kontantinsats: 0 kr</p>
        </div>
        <div class="releasing-block__section-footer">
          <h3>Förläng din privatleasing</h3>
          <table>
            <tr>
              <td><strong>Ny månadskostnad</strong></td>
              <td><strong>{{ releasingData.releasingRatt }} kr/mån**</strong></td>
            </tr>
            <tr>
              <td>Avtalsperiod</td>
              <td>{{ releasingData.releasingAvtalsPeriod }} mån</td>
            </tr>
            <tr>
              <td>Mil</td>
              <td>{{ releasingData.releasingMiltal ? releasingData.releasingMiltal : '2000' }} mil</td>
            </tr>
            <tr>
              <td>Kontantinsats</td>
              <td>0 kr</td>
            </tr>
            <tr>
              <td>Serviceavtal</td>
              <td>Ingår</td>
            </tr>
          </table>
          <div class="form-text">** Leasingavgiften är rörlig och kan påverkas om t.ex. räntan för leasegivarens
            refinansiering eller dess upplåningskostnader förändras. Priserna är frånpriser med 1000mil/år.</div>
        </div>
      </div>
    </div>
    <div class="section section--gray">
      <div class="releasing-block__footer">
        <div class="releasing-block__footer__body">
          <div class="releasing-block__form" v-if="!formSent">
            <form @submit.prevent="sendForm" class="">
              <h3>Ta del av ditt personliga erbjudande</h3>
              <p>Fyll i formuläret nedan så kontaktar en säljare dig inom kort.</p>
              <label>
                Vilket erbjudande är du intresserad av?
              </label>
              <div class="form-row">
                <div class="form-row__col">
                  <div class="form-check form-check--sm form-check--inline">
                    <input id="installmentSelection" name="offerSelection" v-model="offerSelection"
                      class="form-check__input" type="radio" value="avbetalning" />
                    <label class="form-check__label" for="installmentSelection">
                      Jag vill köpa bilen
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-row__col">
                  <div class="form-check form-check--sm form-check--inline">
                    <input id="releasingSelection" name="offerSelection" v-model="offerSelection"
                      class="form-check__input" type="radio" value="releasing" />
                    <label class="form-check__label" for="releasingSelection">
                      Jag vill förlänga min privatleasing
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-row__col">
                  <div class="form-stack form-stack--required">
                    <label>
                      E-post
                    </label>
                    <input v-model="inputEmail" class="form-control" type="email" autocomplete="email"
                      placeholder="Din e-postadress" required>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-row__col">
                  <div class="form-stack form-stack--required">
                    <label>
                      Mobilnummer
                    </label>
                    <input v-model="inputPhone" class="form-control" type="tel" placeholder="Ditt telefonnummer"
                      required autocomplete="tel">
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="form-row__col">
                  <div class="form-check form-check--sm form-check--inline">
                    <input id="acceptedTerms" v-model="acceptedTerms" class="form-check__input" type="checkbox" />
                    <label class="form-check__label" for="acceptedTerms">
                      Jag godkänner att Holmgrens Bil behandlar mina uppgifter som en del i hanteringen av detta ärende.
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-row__col">
                  <button :disabled="!acceptedTerms || sending" class="btn btn--primary btn--block" type="submit">Skicka</button>
                </div>
              </div>
              <div class="form-text">Alla personuppgifter som skickas in till
                Holmgrens Bil kommer att behandlas enligt bestämmelserna i EU:s dataskyddsförordningen (GDPR). <a
                  href="https://www.holmgrensbil.se/om-oss/personuppgiftspolicy" target="_blank">Här</a> kan du läsa
                mer om hur vi behandlar dina personuppgifter.</div>
            </form>
          </div>
          <div class="releasing-block__text" v-else>
            <VcFeedbackMessage success>
              <div slot="header">
                Tack för ditt svar!
              </div>
              En säljare kommer kontakta dig för vidare behandling av ditt ärende.
            </VcFeedbackMessage>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from '../../axios';
import { VcFeedbackMessage } from '@holmgrensbil/vue-components';

export default {
  components: {
    VcFeedbackMessage,
  },
  props: {
  },
  data() {
    return {
      releasingData: {},
      inputEmail: "",
      inputPhone: "",
      acceptedTerms: false,
      offerSelection: "avbetalning",
      id: "",
      sending: false,
      formSent: false
    };
  },
  async mounted() {
    let urlParams = new URLSearchParams(window.location.search);
    this.id = urlParams.get("qv");
    if (this.id && this.id.length > 0) {
      await this.fetchReleasingData();
    }
  },
  methods: {
    async fetchReleasingData() {
      try {
        const { data } = await Axios.get(`/api/Contact/GetReleasingData?id=${this.id}`);
        this.releasingData = data;
      } catch (e) {
        console.warn(e);
      }
    },
    async sendForm() {
      if (!this.inputEmail || !this.inputPhone || !this.acceptedTerms) {
        return false;
      }
      try {
        this.sending = true;
        var data = {
          registrationNumber: this.releasingData.id,
          selectedOffer: this.offerSelection,
          customerEmail: this.inputEmail,
          customerPhone: this.inputPhone
        }
        const { result } = await Axios.post("/api/Contact/SendReleasingForm", data);
        this.formSent = true;
      } catch (e) {
        console.warn(e);
        this.sending = false;
      }
    }
  },
};
</script>
