var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.releasingData.id
    ? _c("div", { staticClass: "releasing-block" }, [
        _c("h2", [_vm._v("Personligt erbjudande för din")]),
        _vm._v(" "),
        _c("h2", [
          _vm._v(
            " " +
              _vm._s(_vm.releasingData.marke) +
              " " +
              _vm._s(_vm.releasingData.modell) +
              " - " +
              _vm._s(_vm.releasingData.id) +
              "\n  "
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "releasing-block__intro" }, [
          _c("p", [
            _vm._v(
              "Vi har tagit fram ett personligt erbjudande som gäller endast dig som idag kör " +
                _vm._s(_vm.releasingData.id) +
                ". Ett\n      unikt erbjudande där du kan välja att förlänga din privatleasing med fördelaktiga villkor eller att köpa ut din\n      bil till ett extra förmånligt pris."
            )
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "text_danger h4" }, [
            _vm._v(
              "Erbjudandet gäller t.o.m. " +
                _vm._s(_vm.releasingData.offerEndDate.split("T")[0])
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "releasing-block__body" }, [
          _c("div", { staticClass: "releasing-block__section" }, [
            _c("div", { staticClass: "releasing-block__section-header" }, [
              _c("h4", [
                _vm._v(
                  "Köp din " + _vm._s(_vm.releasingData.id) + " för endast"
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "releasing-block__offer h1" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.releasingData.avbetalningRatt) +
                    " kr/mån*\n        "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Ditt personliga pris " +
                    _vm._s(_vm.releasingData.forsaljningRatt) +
                    " kr"
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "releasing-block__section-footer" }, [
              _c("h3", [_vm._v("Avbetalning")]),
              _vm._v(" "),
              _c("table", [
                _c("tr", [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("td", [
                    _c("strong", [
                      _vm._v(
                        _vm._s(_vm.releasingData.avbetalningRatt) + " kr/mån*"
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("Avtalsperiod")]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(_vm._s(_vm.releasingData.avbetAvtalsPeriod) + " mån")
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("Ränta")]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(_vm.releasingData.avbetRantaRatt))])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("Kontantinsats kr")]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(_vm._s(_vm.releasingData.kontantinsatsRatt) + " kr")
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("Ditt personliga pris")]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(_vm._s(_vm.releasingData.forsaljningRatt) + " kr")
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-text" }, [
                _vm._v(
                  "* Räntan är rörlig och kan komma att påverkas både upp och ned. Priset är räknat på 20%\n          kontantinsats och " +
                    _vm._s(_vm.releasingData.avbetRVRatt) +
                    " restvärde."
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "releasing-block__section" }, [
            _c("div", { staticClass: "releasing-block__section-header" }, [
              _c("h4", [_vm._v("Förläng din privatleasing för endast")]),
              _vm._v(" "),
              _c("div", { staticClass: "releasing-block__offer h1" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.releasingData.releasingRatt) +
                    " kr/mån**\n        "
                )
              ]),
              _vm._v(" "),
              _c("p", [_vm._v("Kontantinsats: 0 kr")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "releasing-block__section-footer" }, [
              _c("h3", [_vm._v("Förläng din privatleasing")]),
              _vm._v(" "),
              _c("table", [
                _c("tr", [
                  _vm._m(1),
                  _vm._v(" "),
                  _c("td", [
                    _c("strong", [
                      _vm._v(
                        _vm._s(_vm.releasingData.releasingRatt) + " kr/mån**"
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("Avtalsperiod")]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(_vm.releasingData.releasingAvtalsPeriod) + " mån"
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("Mil")]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.releasingData.releasingMiltal
                          ? _vm.releasingData.releasingMiltal
                          : "2000"
                      ) + " mil"
                    )
                  ])
                ]),
                _vm._v(" "),
                _vm._m(2),
                _vm._v(" "),
                _vm._m(3)
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-text" }, [
                _vm._v(
                  "** Leasingavgiften är rörlig och kan påverkas om t.ex. räntan för leasegivarens\n          refinansiering eller dess upplåningskostnader förändras. Priserna är frånpriser med 1000mil/år."
                )
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "section section--gray" }, [
          _c("div", { staticClass: "releasing-block__footer" }, [
            _c("div", { staticClass: "releasing-block__footer__body" }, [
              !_vm.formSent
                ? _c("div", { staticClass: "releasing-block__form" }, [
                    _c(
                      "form",
                      {
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                            return _vm.sendForm($event)
                          }
                        }
                      },
                      [
                        _c("h3", [
                          _vm._v("Ta del av ditt personliga erbjudande")
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "Fyll i formuläret nedan så kontaktar en säljare dig inom kort."
                          )
                        ]),
                        _vm._v(" "),
                        _c("label", [
                          _vm._v(
                            "\n              Vilket erbjudande är du intresserad av?\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-row__col" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-check form-check--sm form-check--inline"
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.offerSelection,
                                      expression: "offerSelection"
                                    }
                                  ],
                                  staticClass: "form-check__input",
                                  attrs: {
                                    id: "installmentSelection",
                                    name: "offerSelection",
                                    type: "radio",
                                    value: "avbetalning"
                                  },
                                  domProps: {
                                    checked: _vm._q(
                                      _vm.offerSelection,
                                      "avbetalning"
                                    )
                                  },
                                  on: {
                                    change: function($event) {
                                      _vm.offerSelection = "avbetalning"
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-check__label",
                                    attrs: { for: "installmentSelection" }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    Jag vill köpa bilen\n                  "
                                    )
                                  ]
                                )
                              ]
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-row__col" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-check form-check--sm form-check--inline"
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.offerSelection,
                                      expression: "offerSelection"
                                    }
                                  ],
                                  staticClass: "form-check__input",
                                  attrs: {
                                    id: "releasingSelection",
                                    name: "offerSelection",
                                    type: "radio",
                                    value: "releasing"
                                  },
                                  domProps: {
                                    checked: _vm._q(
                                      _vm.offerSelection,
                                      "releasing"
                                    )
                                  },
                                  on: {
                                    change: function($event) {
                                      _vm.offerSelection = "releasing"
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-check__label",
                                    attrs: { for: "releasingSelection" }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    Jag vill förlänga min privatleasing\n                  "
                                    )
                                  ]
                                )
                              ]
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-row__col" }, [
                            _c(
                              "div",
                              {
                                staticClass: "form-stack form-stack--required"
                              },
                              [
                                _c("label", [
                                  _vm._v(
                                    "\n                    E-post\n                  "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.inputEmail,
                                      expression: "inputEmail"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "email",
                                    autocomplete: "email",
                                    placeholder: "Din e-postadress",
                                    required: ""
                                  },
                                  domProps: { value: _vm.inputEmail },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.inputEmail = $event.target.value
                                    }
                                  }
                                })
                              ]
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-row__col" }, [
                            _c(
                              "div",
                              {
                                staticClass: "form-stack form-stack--required"
                              },
                              [
                                _c("label", [
                                  _vm._v(
                                    "\n                    Mobilnummer\n                  "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.inputPhone,
                                      expression: "inputPhone"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "tel",
                                    placeholder: "Ditt telefonnummer",
                                    required: "",
                                    autocomplete: "tel"
                                  },
                                  domProps: { value: _vm.inputPhone },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.inputPhone = $event.target.value
                                    }
                                  }
                                })
                              ]
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-row__col" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-check form-check--sm form-check--inline"
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.acceptedTerms,
                                      expression: "acceptedTerms"
                                    }
                                  ],
                                  staticClass: "form-check__input",
                                  attrs: {
                                    id: "acceptedTerms",
                                    type: "checkbox"
                                  },
                                  domProps: {
                                    checked: Array.isArray(_vm.acceptedTerms)
                                      ? _vm._i(_vm.acceptedTerms, null) > -1
                                      : _vm.acceptedTerms
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a = _vm.acceptedTerms,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.acceptedTerms = $$a.concat([
                                              $$v
                                            ]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.acceptedTerms = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.acceptedTerms = $$c
                                      }
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-check__label",
                                    attrs: { for: "acceptedTerms" }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    Jag godkänner att Holmgrens Bil behandlar mina uppgifter som en del i hanteringen av detta ärende.\n                  "
                                    )
                                  ]
                                )
                              ]
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-row__col" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn--primary btn--block",
                                attrs: {
                                  disabled: !_vm.acceptedTerms || _vm.sending,
                                  type: "submit"
                                }
                              },
                              [_vm._v("Skicka")]
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _vm._m(4)
                      ]
                    )
                  ])
                : _c(
                    "div",
                    { staticClass: "releasing-block__text" },
                    [
                      _c("VcFeedbackMessage", { attrs: { success: "" } }, [
                        _c(
                          "div",
                          { attrs: { slot: "header" }, slot: "header" },
                          [
                            _vm._v(
                              "\n              Tack för ditt svar!\n            "
                            )
                          ]
                        ),
                        _vm._v(
                          "\n            En säljare kommer kontakta dig för vidare behandling av ditt ärende.\n          "
                        )
                      ])
                    ],
                    1
                  )
            ])
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("strong", [_vm._v("Ny månadskostnad")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("strong", [_vm._v("Ny månadskostnad")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", [_vm._v("Kontantinsats")]),
      _vm._v(" "),
      _c("td", [_vm._v("0 kr")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", [_vm._v("Serviceavtal")]),
      _vm._v(" "),
      _c("td", [_vm._v("Ingår")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-text" }, [
      _vm._v(
        "Alla personuppgifter som skickas in till\n              Holmgrens Bil kommer att behandlas enligt bestämmelserna i EU:s dataskyddsförordningen (GDPR). "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "https://www.holmgrensbil.se/om-oss/personuppgiftspolicy",
            target: "_blank"
          }
        },
        [_vm._v("Här")]
      ),
      _vm._v(
        " kan du läsa\n              mer om hur vi behandlar dina personuppgifter."
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }