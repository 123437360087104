<template>
  <div class="eon-result">
    <TheHeader v-if="products.length && selectedProductType !== EonProductTypes.TyreBuild"
      :count="channel.data.count"
      :vehicle="TyreSpec.shortDisplayName">
    </TheHeader>

    <div v-if="topQuerying || isLoading" class="eon-result__querying-content">
      <FontAwesomeIcon
        :icon="faSpinnerThird"
        :spin="true">
      </FontAwesomeIcon>
    </div>
    <TheGrid v-if="products.length"
      :products="products"
      :selectedProductType="selectedProductType"
      :regNr="TyreSpec.regNr"
      :querying="topQuerying || bottomQuerying"
      :isBuildWheel="isBuildWheel"
      :selectedProduct="selectedProduct"
      @onNext="onNext">
    </TheGrid>
    <NoResults
      v-else-if="queriedAt"
      :sizes="TyreSpec.recommendedSizes"
      :tyreSize="tyreSize"
      :carName="TyreSpec.carName"
      @click="onGoToRecommendedSize"
    >
    </NoResults>

    <div v-if="bottomQuerying" class="eon-result__querying-content-end">
      <FontAwesomeIcon
        :icon="faSpinnerThird"
        :spin="true">
      </FontAwesomeIcon>
    </div>
  </div>
</template>

<script>
import ChannelEvents from '../../constants/ChannelEvents';
import EonProductTypes from '../eon-filter-page/constants/EonProductTypes.js';
import TheGrid from './TheGrid.vue';
import TheHeader from './TheHeader.vue';
import NoResults from './NoResults.vue';
import EonProduct from '../../models/EonProduct';
import gtm from '../../utils/gtm'
import EonProductTypeToGtmList from '../../constants/EonProductTypeToGtmList';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faSpinnerThird, faCameraAlt } from "@fortawesome/pro-light-svg-icons";
import GtmEvents from '../../constants/GtmEvents';
import ChannelQuery from '../../models/ChannelQuery';
import EonQueryParams from '../../constants/EonQueryParams';
import { EventBus } from '../../utils/event-bus';

export default {
  components: {
    TheGrid,
    TheHeader,
    NoResults,
    FontAwesomeIcon,
  },
  props: {
    channel: {
      type: Object,
      required: true,
    },
    TyreSpec: Object,
    selectedProductType: String,
    isBuildWheel: Boolean,
    selectedProduct: Object,
    isLoading: Boolean,
  },
  data() {
    return {
      products: [],
      queriedAt: null,
      topQuerying: false,
      bottomQuerying: false,
      tyreSize: null,
      faSpinnerThird,
      EonProductTypes,
    };
  },
  created() {
    console.log(this.TyreSpec, this)
    this.channel.on(ChannelEvents.QueryRequest, this.onQueryRequest);
    this.channel.on(ChannelEvents.QuerySuccess, this.onQuerySuccess);
    this.tyreSize = { ...this.TyreSpec.tyreSize };
    this.topQuerying = true
    if (this.channel.executedQueries.length && !this.isLoading) {
      this.onQuerySuccess();
    }
  },
  destroyed() {
    this.channel.off(ChannelEvents.QuerySuccess, this.onQuerySuccess);
  },
  methods: {
    onQuerySuccess() {
      this.products = this.channel.data.data.products.map(p => new EonProduct(p));
      this.queriedAt = new Date;
      this.topQuerying = false
      const firstIndex = (this.channel.lastQuery.params.page - 1) * 25;
      const impressions = this.products.slice(firstIndex, this.products.length).map((product, i) => {
        const tireData = product.tireData;
        return {
          ...tireData,
          list: EonProductTypeToGtmList[this.selectedProductType],
          position: i + firstIndex + 1,
        }
      })

      const data = {
        impressions: [...impressions],
        currencyCode: 'SEK'
      }
      gtm.tireCardLoad(GtmEvents.Impressions, data)
      this.$emit('onQuerySuccess')
    },

    onQueryRequest() {
      this.topQuerying = true;
    },

    async onNext() {
      if (this.bottomQuerying || !this.queriedAt || !this.channel.hasNextPage) {
        return;
      }

      this.bottomQuerying = true;
      await this.channel.queryNextPage();
      this.bottomQuerying = false;
    },
    onGoToRecommendedSize(tyreSize) {
      this.TyreSpec.set({
        tyreSize,
      });
      if(this.channel) {
        const query = new ChannelQuery(this.channel.lastQuery);
        const params = [
          { name: EonQueryParams.Width, value: this.TyreSpec.tyreSize.width },
          { name: EonQueryParams.AspectRatio, value: this.TyreSpec.tyreSize.aspectRatio },
          { name: EonQueryParams.Diameter, value: this.TyreSpec.tyreSize.diameter },
        ];
        params.forEach(d => {
            query.setParam(d.name, d.value);
        });
        EventBus.$emit('set-filter', params);
        this.tyreSize = { ...this.TyreSpec.tyreSize };
        this.channel.execute(query);
      } else {
        window.location.href = this.buttonHref;
      }
    }
  },
}
</script>
