<template>
  <div v-if="productObjects.length" class="section__content section__content--lg">
    <div class="section__header section__header--sm">
        {{ title }}
        <a :href="link" class="header-meta link link--underline link--icon">
            <span>{{ linkTitle }}</span>
            <i class="fal fa-angle-right"></i>
        </a>
    </div>
    <div class="eon-product-slider">
      <EonProductSlider :productObjects="productObjects"></EonProductSlider>
      <portal-target name="eon-product-services-modal"></portal-target>
    </div>
  </div>
</template>

<script>
import EonProductSlider from '../shared/ecom/EonProductSlider.vue';
import EonApi from '../../models/EonApi';

export default {
  components: {
    EonProductSlider,
  },
  props: {
    limit: {
      type: Number,
      default: 3
    },
    typeId: {
      type: Number,
      default: 28
    },
    regNr: {
      type: String,
      default: ''
    },
    title: String,
    link: String,
    linkTitle: String,
  },
  async created() {
    try {
      //const res = await EonApi.getProducts({ typeId: this.product.typeId, tyreType: this.product.tyreTypeId, condition: [this.product.condition.type], limit: this.limit });
      const res = await EonApi.getProducts({ typeId: this.typeId, limit: this.limit, licenseplate: this.regNr });
      console.log(res.data);
      if (res.data) {
        return this.productObjects = res.data.products;
      }
    } catch (error) {
      console.log("unable to find associated products", error);
    }
  },
  data() {
    return {
      productObjects: [],
    };
  },
  methods: {

  },
}
</script>
