<template>
  <div class="configurator-section finance-selector">
    <h4 class="configurator-section__title">
      <i class="fal fa-money-check configurator-section__icon"></i>
      {{ lang.Summation.FinancingHeading }}
    </h4>
    <div
      v-if="
        financingSummary[FinanceOptions.CASH] &&
          financingConfig[FinanceOptions.CASH].display
      "
      class="form-row"
    >
      <div class="form-row__col">
        <ConfiguratorOptionBox
          :selected="selected === FinanceOptions.CASH"
          :heading="lang.Summation.FinancingOptionCash"
          :smallText="cashSmallText"
          :price="cashPrice"
          :hasSubOption="financingSummary.cash.vatToggle"
          :lang="lang"
          :modalContent="financingSummary.cash.modalContent"
          :ribbonText="financingSummary.cash.ribbonText"
          @click="$emit('change-finance-option', FinanceOptions.CASH)"
        >
          <div slot="content">
            <SubOptionsToggle
              :option="cashSubOptions"
              @change="onChangeTaxSetting($event)"
            >
            </SubOptionsToggle>
          </div>
        </ConfiguratorOptionBox>
      </div>
    </div>
    <div
      v-if="
        financingSummary[FinanceOptions.INSTALLMENT] &&
          financingConfig[FinanceOptions.INSTALLMENT].display
      "
      class="form-row"
    >
      <div class="form-row__col">
        <ConfiguratorOptionBox
          :selected="selected === FinanceOptions.INSTALLMENT"
          :heading="lang.Summation.FinancingOptionInstallment"
          :smallText="`(${lang.Summation.IncludingTax})`"
          :price="{
            current: financingSummary.installment.monthlyCost,
            ordinary: financingSummary.installment.ordinaryMonthlyCost,
            suffix: ' kr/månad'
          }"
          :hasSubOption="true"
          :lang="lang"
          :modalContent="financingSummary.installment.modalContent"
          :ribbonText="financingSummary.installment.ribbonText"
          @click="$emit('change-finance-option', FinanceOptions.INSTALLMENT)"
        >
          <div slot="content">
            <div class="form-row">
              <ConfiguratorSubSelect
                :option="downPaymentOptions"
                @change="onChangeDownPayment"
                :selectedValue="
                  financingSettings[FinanceOptions.INSTALLMENT].downPayment
                "
              />
              <ConfiguratorSubSelect
                :option="periodOptions"
                @change="onChangePeriod"
                :selectedValue="
                  financingSettings[FinanceOptions.INSTALLMENT].period.months
                "
              />
            </div>
            <div class="finance-selector__information-field">
              <SummationList
                :lang="lang"
                :financing="financingSummary[FinanceOptions.INSTALLMENT]"
                :selectedFinancing="selected"
              />
            </div>
          </div>
        </ConfiguratorOptionBox>
      </div>
    </div>
    <div
      v-if="
        financingSummary[FinanceOptions.LEASING] &&
          financingConfig[FinanceOptions.LEASING].display
      "
      class="form-row"
    >
      <div class="form-row__col">
        <ConfiguratorOptionBox
          :selected="selected === FinanceOptions.LEASING"
          :heading="lang.Summation.FinancingOptionLeasing"
          :smallText="`(${lang.Summation.ExcludingTax})`"
          :price="{
            current: financingSummary.leasing.monthlyCost,
            ordinary: financingSummary.leasing.ordinaryMonthlyCost,
            suffix: ' kr/månad'
          }"
          :hasSubOption="true"
          :lang="lang"
          :modalContent="financingSummary.leasing.modalContent"
          :ribbonText="financingSummary.leasing.ribbonText"
          @click="$emit('change-finance-option', FinanceOptions.LEASING)"
        >
          <div slot="content">
            <div class="form-row">
              <ConfiguratorSubSelect
                :option="downPaymentOptions"
                @change="onChangeDownPayment"
                :selectedValue="
                  financingSettings[FinanceOptions.LEASING].downPayment
                "
              />
              <ConfiguratorSubSelect
                :option="periodOptions"
                @change="onChangePeriod"
                :selectedValue="
                  financingSettings[FinanceOptions.LEASING].period.months
                "
              />
            </div>
            <div class="finance-selector__information-field">
              <SummationList
                :lang="lang"
                :financing="financingSummary[FinanceOptions.LEASING]"
                :selectedFinancing="selected"
              />
            </div>
          </div>
        </ConfiguratorOptionBox>
      </div>
    </div>
    <div
      v-if="
        financingSummary[FinanceOptions.PRIVATELEASING] &&
          financingConfig[FinanceOptions.PRIVATELEASING].display
      "
      class="form-row"
    >
      <div class="form-row__col">
        <ConfiguratorOptionBox
          :selected="selected === FinanceOptions.PRIVATELEASING"
          :heading="lang.Summation.FinancingOptionPrivateLeasing"
          :smallText="`(${lang.Summation.IncludingTax})`"
          :price="{
            current: financingSummary.privateLeasing.monthlyCost,
            ordinary: financingSummary.privateLeasing.ordinaryMonthlyCost,
            suffix: ' kr/månad',
            attention: true
          }"
          :hasSubOption="true"
          :lang="lang"
          :modalContent="financingSummary.privateLeasing.modalContent"
          :ribbonText="financingSummary.privateLeasing.ribbonText"
          @click="$emit('change-finance-option', FinanceOptions.PRIVATELEASING)"
        >
          <div slot="content">
            <div class="finance-selector__information-field">
              <SummationList
                :lang="lang"
                :financing="financingSummary[FinanceOptions.PRIVATELEASING]"
                :selectedFinancing="selected"
              />
            </div>
          </div>
        </ConfiguratorOptionBox>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import ConfiguratorOptionBox from "./ConfiguratorOptionBox.vue";
import SubOptionsToggle from "../shared/option-box/SubOptionsToggle.vue";
import SubOptionsSelect from "../shared/option-box/SubOptionsSelect.vue";
import FinanceOptions from '../../constants/FinanceOptions';
import ConfiguratorSubSelect from "./ConfiguratorSubSelect.vue";
import SummationList from "./SummationList.vue";
import store from "./store";
import Axios from "axios";

export default {
  components: {
    ConfiguratorOptionBox,
    SubOptionsToggle,
    SubOptionsSelect,
    SummationList,
    ConfiguratorSubSelect
  },
  props: {
    financingConfig: Object,
    financingSummary: Object,
    selected: String,
    displayTax: Boolean,
    lang: Object
  },
  data() {
    return {
      FinanceOptions,
      selectedDownPayment: 0,
      financingSettings: {
        installment: {
          period: {},
          downPayment: 0
        },
        leasing: {
          period: {},
          downPayment: 0
        }
      }
    };
  },
  computed: {
    cashSubOptions() {
      return {
        name: "cashSubOptions",
        values: [
          {
            name: this.lang.Summation.ExcludingTax,
            selected: !this.displayTax,
            value: 1
          }
        ]
      };
    },
    cashSmallText() {
      return this.displayTax
        ? `(${this.lang.Summation.IncludingTax})`
        : `(${this.lang.Summation.ExcludingTax})`;
    },
    cashPrice() {
      const current = this.displayTax
        ? this.financingSummary.cash.currentPrice
        : this.financingSummary.cash.currentPriceNoVat;
      const ordinary = this.displayTax
        ? this.financingSummary.cash.ordinaryPrice
        : this.financingSummary.cash.ordinaryPriceNoVat;
      return {
        current,
        ordinary,
        suffix: " kr"
      };
    },
    selectedOptions() {
      let periods = [];
      let minimumDownPayment = 0;
      let downPaymentHeading = "";

      switch (this.selected) {
        case FinanceOptions.INSTALLMENT: {
          periods = this.financingConfig.installment.periods;
          minimumDownPayment = this.financingConfig.installment
            .minimumDownPayment;
          downPaymentHeading = "Kontantinsats";
          break;
        }
        case FinanceOptions.LEASING: {
          periods = this.financingConfig.leasing.periods;
          minimumDownPayment = this.financingConfig.leasing.minimumDownPayment;
          downPaymentHeading = "Förhöjd första hyra";
          break;
        }
        case FinanceOptions.PRIVATELEASING: {
          minimumDownPayment = this.financingConfig.privateLeasing
            .minimumDownPayment;
          downPaymentHeading = "Förhöjd första hyra";
          break;
        }
        case FinanceOptions.OPLEASING: {
          minimumDownPayment = this.financingConfig.privateLeasing
            .minimumDownPayment;
          downPaymentHeading = "Förhöjd första hyra";
          break;
        }
        case FinanceOptions.BLLEASING: {
          minimumDownPayment = this.financingConfig.privateLeasing
            .minimumDownPayment;
          downPaymentHeading = "Förhöjd första hyra";
          break;
        }
        default:
          break;
      }

      return {
        periods,
        minimumDownPayment,
        downPaymentHeading
      };
    },
    periodOptions() {
      const values = this.selectedOptions.periods.map(period => {
        return {
          name: period.name,
          value: period.months,
          selected: period.selected
        };
      });
      return {
        heading: "Avtalsperiod",
        required: true,
        values
      };
    },
    downPaymentOptions() {
      const list = [];
      if (
        this.selected &&
        this.selected !== FinanceOptions.CASH &&
        this.selected !== FinanceOptions.PRIVATELEASING &&
        this.selected !== FinanceOptions.OPLEASING
      ) {
        const selectedPeriod = this.financingSettings[this.selected].period;
        const minimumDownPayment = this.selectedOptions.minimumDownPayment;
        const maximumDownPayment =
          selectedPeriod.residualPrecentage === 0
            ? 80
            : 100 - selectedPeriod.residualPrecentage || 0;

        if (minimumDownPayment === 0 && maximumDownPayment === 0) {
          list.push(0);
        } else {
          let downPayment;
          for (
            downPayment = minimumDownPayment;
            downPayment <= maximumDownPayment - 10;
            downPayment = downPayment + 10
          ) {
            list.push(downPayment);
          }
        }
      }
      return {
        heading: this.selectedOptions.downPaymentHeading || "",
        required: true,
        values: list.map((v, i) => ({
          name: `${v}%`,
          value: v,
          selected: i === 0
        }))
      };
    }
  },
  watch: {
    async selected() {
      this.calculate();
    }
  },
  created() {
    console.log(this.financingConfig);
    if (this.financingConfig.installment.display) {
      this.financingSettings.installment.downPayment = this.financingConfig.installment.selectedDownPayment;
      this.financingSettings.installment.period = this.financingConfig.installment.periods.find(
        period => period.selected
      );
      console.log(this.financingSettings);
    }
    if (this.financingConfig.leasing.display) {
      this.financingSettings.leasing.downPayment = this.financingConfig.leasing.selectedDownPayment;
      this.financingSettings.leasing.period = this.financingConfig.leasing.periods.find(
        period => period.selected
      );
    }
  },
  methods: {
    onChangeTaxSetting(value) {
      this.$emit("change-tax-setting", value);
    },
    onChangePeriod(val) {
      const selectedPeriod = this.selectedOptions.periods.find(
        period => period.months === Number(val)
      );
      this.financingSettings[this.selected].period = selectedPeriod;
      if (
        this.downPaymentOptions.values.filter(
          value =>
            value.value === this.financingSettings[this.selected].downPayment
        ).length < 1
      ) {
        this.financingSettings[
          this.selected
        ].downPayment = this.downPaymentOptions.values[0]?.value;
      }
      this.calculate();
    },
    onChangeDownPayment(val) {
      this.financingSettings[this.selected].downPayment = Number(val);
      this.calculate();
    },
    calculate() {
      if (
        this.selected !== FinanceOptions.CASH &&
        this.selected !== FinanceOptions.PRIVATELEASING &&
        this.selected !== FinanceOptions.OPLEASING
      ) {
        const data = {
          downpaymentPercentage: this.financingSettings[this.selected]
            .downPayment,
          period: this.financingSettings[this.selected].period.months,
          residualPercentage: this.financingSettings[this.selected].period
            .residualPrecentage
        };
        store.calculate(data);
      }
    }
  }
};
</script>
