<template>
  <div class="checkout">
    <div class="checkout__container">
      <VcFeedbackMessage
        v-if="paymentError"
        danger
        class="checkout__payment-error"
      >
        <strong>
          <i class="fas fa-exclamation-triangle"></i> {{ genericPaymentError }}
        </strong>
        <br />
        <small>
          Kontrollera att du har tillräckligt med pengar på ditt konto om du
          betalade med kort.
          <span v-if="selectedDepartment">
            Ring till Holmgrens Bil {{ selectedDepartment.name }} på
            {{ selectedDepartment.phoneNr }} eller läs mer
            <a
              target="_blank"
              style="text-decoration: underline"
              :href="selectedDepartment.departmentUrl"
              >här.</a
            >
          </span>
        </small>
      </VcFeedbackMessage>
      <CheckoutSection arrow>
        <div slot="header">
          <i class="fal fa-shopping-cart"></i>
          {{ `${lang.Checkout.CheckoutTitle} ${checkoutTypeHeader}` }}

          <span
            @click="activeSendCartModal = true"
            class="link link--primary checkout__send-cart"
            ><i class="fal fa-share-square"></i>
            {{ lang.Checkout.SendCart.SendCartLinkText }}</span
          >
        </div>
        <div slot="content">
          <Cart :isAccessoryCart="isAccessoryCheckout"></Cart>
          <UpsellProducts
            v-if="hasUpsellProducts"
            :upsellProducts="upsellProducts"
            @added="onUpsellAdded"
          ></UpsellProducts>
          <CartSummary></CartSummary>
        </div>
      </CheckoutSection>
      <CheckoutSection v-if="shouldShowRegNrInput" arrow>
        <div slot="header">
          <i class="fal fa-car"></i>
          <span>{{ lang.Checkout.VehicleInformation.Title }}</span>
        </div>
        <ChooseVehicle
          slot="content"
          :lang="lang"
          @setRegNumber="onSetRegNumber"
          @setMultipleRegNumbers="onSetMultipleRegNumbers"
        />
      </CheckoutSection>
      <CheckoutSection arrow>
        <div slot="header">
          <i class="fal fa-car-garage"></i>
          <span v-if="!urlSiteId">{{ lang.Checkout.Department.Title }}</span>
          <span v-else>Däckhotell</span>
        </div>
        <ChoosePickup
          @changePickup="sendGTMEvent"
          :url-site-id="urlSiteId"
          slot="content"
          :lang="lang"
          :isAccessoryCheckout="isAccessoryCheckout"
        ></ChoosePickup>
      </CheckoutSection>
      <CheckoutSection arrow>
        <div slot="header">
          <i class="fal fa-user"></i>
          {{ lang.Checkout.CustomerDetails.Title }}
        </div>
        <CustomerDetails
          slot="content"
          :lang="lang"
          @ssnChange="ssnChange"
        ></CustomerDetails>
      </CheckoutSection>
      <CheckoutSection>
        <div slot="header">
          <i class="fal fa-credit-card"></i>
          {{ lang.Checkout.Payment.Title }}
        </div>
        <ChoosePayment
          slot="content"
          :lang="lang"
          :prefilledSsn="ssn"
          @changePayment="sendGTMEvent"
        ></ChoosePayment>

        <div slot="summary">
          <CartSummary></CartSummary>

          <div
            class="
              form-check form-check--sm form-check--inline
              checkout__accept-terms
            "
          >
            <input
              id="acceptedTerms"
              v-model="acceptedTerms"
              class="form-check__input"
              type="checkbox"
            />
            <label class="form-check__label" for="acceptedTerms">
              Jag godkänner
              <a
                style="text-decoration: underline"
                target="_blank"
                :href="integrityUrl"
                >integritetspolicyn</a
              >
              och
              <a
                style="text-decoration: underline"
                target="_blank"
                :href="purchaseTermUrl"
                >köpvillkoren</a
              >
              för Holmgrens Bil.
            </label>
          </div>

          <VcFeedbackMessage v-if="!valid" danger slim center>
            <strong>
              {{ lang.Checkout.Payment.Options }}
            </strong>
          </VcFeedbackMessage>

          <VcFeedbackMessage v-if="submitError" danger slim center>
            <strong>
              {{ submitError }}
            </strong>
          </VcFeedbackMessage>

          <a
            class="btn btn--lg btn--block btn--primary"
            id="checkout-submit"
            :class="{ disabled: !valid }"
            @click="onSubmit"
          >
            {{ lang.Checkout.SubmitLabel }}
          </a>
          <p class="form-text" v-html="lang.General.PrivacyPolicyText"></p>
        </div>
      </CheckoutSection>
    </div>

    <VcModal v-if="activeSendCartModal" @close="onShareModalClose">
      <div slot="header">
        {{ lang.Checkout.SendCart.SendCartLinkText }}
      </div>
      <div slot="content">
        <div v-if="!cartShared">
          <h5 class="u-fw-regular">
            {{ lang.Checkout.SendCart.SendCartModalText }}
          </h5>
          <form @submit.prevent="shareCart">
            <div class="form-stack form-stack--required">
              <label> E-post </label>
              <input
                v-model="shareToEmail"
                type="email"
                placeholder="Ange e-post..."
                required="required"
                class="form-control"
              />
            </div>
            <button class="btn btn--primary" type="submit">
              {{ lang.Checkout.SendCart.SendCartLinkText }}
            </button>
          </form>
        </div>
        <div v-else>
          <div class="feedback-message feedback-message--success">
            <div class="feedback-message__header">Varukorg delad</div>
            <div class="feedback-message__text">
              Varukorgen har delats med e-posten {{ shareToEmail }}.
            </div>
          </div>
        </div>
      </div>
    </VcModal>
  </div>
</template>

<script>
import { VcFeedbackMessage, VcModal } from "@holmgrensbil/vue-components";
import Cart from "../shared/ecom/Cart.vue";
import CartSummary from "../shared/ecom/CartSummary.vue";
import UpsellProducts from "./UpsellProducts.vue";
import CheckoutSection from "./CheckoutSection.vue";
import ChoosePickup from "./ChoosePickup.vue";
import ChoosePayment from "./ChoosePayment.vue";
import ChooseVehicle from "./ChooseVehicle.vue";
import CustomerDetails from "./CustomerDetails.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import siteHeaderCartBadge from "../../components/site-header-cart-badge";
import OrderApi from "../../models/OrderApi";
import Steps from "./constants/Steps";
import GtmEvents from "../../constants/GtmEvents";
import gtm from "../../utils/gtm";
import CartApi from "../../models/CartApi";
import { getQueryParam } from "../../utils/query-params";
import Cookies from "js-cookie";
import LineItemCustomDataKeys from "../../constants/LineItemCustomDataKeys";
import CartStoreKeys from "../../constants/CartStoreKeys";

export default {
  components: {
    VcFeedbackMessage,
    CheckoutSection,
    Cart,
    CartSummary,
    ChoosePickup,
    CustomerDetails,
    ChoosePayment,
    UpsellProducts,
    VcModal,
    ChooseVehicle,
  },
  props: {
    lang: {
      type: Object,
      required: true,
    },
    paymentError: Boolean,
  },
  data() {
    return {
      submitError: null,
      genericPaymentError: "Något gick snett med din betalning.",
      sentEvents: [],
      upsellProducts: [],
      ssn: "",
      acceptedTerms: false,
      activeSendCartModal: false,
      shareToEmail: "",
      cartShared: false,
      urlSiteId: null,
      regNr: "",
      hasMultipleRegNumbers: false,
      checkoutTypeHeader: "däckshop",
    };
  },
  created() {
    console.log("loaded");
    if (this.isAccessoryCheckout) {
      OrderApi.setCommonHeader("storeKey", CartStoreKeys.Accessories);
      CartApi.setCommonHeader("storeKey", CartStoreKeys.Accessories);
      this.checkoutTypeHeader = "tillbehör";
    }
    const urlSiteId = getQueryParam("siteId");
    this.urlSiteId = urlSiteId && urlSiteId.length ? urlSiteId : null;
  },
  async mounted() {
    const cartId = getQueryParam("cartId");
    if (cartId && cartId.length) {
      await this.fetchCartById(cartId);
    } else {
      await this.fetchCart();
    }

    await this.fetchUpsellProducts();
    this.sendGTMEvent(Steps.checkout);
  },
  watch: {
    cartLines() {
      if (this.cartHasFetched && this.cartLines.length === 0) {
        window.location.href = window.EonConfig.productListBaseUrl;
      }
    },

    cartLineParents() {
      if (this.cartHasFetched) {
        siteHeaderCartBadge.setCount(this.cartLineParents.length);
      }
    },

    fieldsValid() {
      if (
        this.fieldsValid &&
        !this.sentEvents.find(
          (event) => event.order === Steps.customerDetails.order
        )
      ) {
        this.sendGTMEvent(Steps.customerDetails);
        this.sentEvents = [...this.sentEvents, Steps.customerDetails];
      }
    },
    cartSelectedPayment() {
      if (
        this.cartSelectedPayment &&
        !this.sentEvents.find((event) => event.order === Steps.payment.order)
      ) {
        this.sendGTMEvent(Steps.payment, null, this.cartSelectedPayment);
        this.sentEvents = [...this.sentEvents, Steps.payment];
      }
    },
  },
  computed: {
    ...mapState({
      cart: (state) => state.cart,
      cartHasFetched: (state) => state.cart.hasFetched,
      selectedPickup: (state) => state.pickup.selected,
      tyreHotelOrder: (state) => state.pickup.tyreHotelOrder,
    }),

    ...mapGetters({
      cartLines: "cart/lines",
      cartLineParents: "cart/lineParents",
      cartSelectedPayment: "cart/selectedPayment",
      cartProductIds: "cart/productIds",
      fieldsExport: "fields/export",
      invalidFields: "fields/invalid",
      paymentInputsExport: "paymentInputs/export",
      invalidPaymentInputs: "paymentInputs/invalid",
      pickupDepartmentByDepartmentNr: "pickup/departmentByDepartmentNr",
    }),

    isAccessoryCheckout() {
      return window.ecomConfig?.storeKey === "accessory";
    },
    selectedDepartment() {
      if (this.selectedPickup) {
        return this.pickupDepartmentByDepartmentNr(
          this.selectedPickup.departmentNr
        );
      }
    },

    shouldShowRegNrInput() {
      if (this.cart.cart.lineItems[0]) {
        const customDataLine =
          this.cart.cart.lineItems[0].customData &&
          this.cart.cart.lineItems[0].customData.find(
            (l) => l.name === LineItemCustomDataKeys.RegistrationNumber
          );
        if (customDataLine) {
          this.regNr = customDataLine.value;
          return false;
        } else if (this.isAccessoryCheckout) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },

    hasUpsellProducts() {
      return this.upsellProducts.length;
    },

    paymentValid() {
      return !!this.cartSelectedPayment;
    },

    fieldsValid() {
      return this.invalidFields.length === 0;
    },

    paymentInputsValid() {
      return this.invalidPaymentInputs.length === 0;
    },

    pickupValid() {
      return !!this.selectedPickup;
    },

    regNrValid() {
      return this.hasMultipleRegNumbers || this.isAccessoryCheckout
        ? true
        : /^\w{3}\d{2}(\w{1}|\d{1})$/.test(this.regNr);
    },

    integrityUrl() {
      return window.EonConfig.integrityPolicyPageUrl;
    },

    purchaseTermUrl() {
      return window.EonConfig.purchaseTermsPageUrl;
    },

    valid() {
      const flags = [
        this.paymentValid,
        this.fieldsValid,
        this.paymentInputsValid,
        this.pickupValid,
        this.acceptedTerms,
        this.regNrValid,
      ];

      return flags.every(Boolean);
    },
  },
  methods: {
    ...mapActions({
      fetchCart: "cart/fetch",
      fetchCartById: "cart/fetchById",
    }),

    onUpsellAdded(product) {
      this.fetchCart();
      this.upsellProducts = this.upsellProducts.filter(
        (p) => p.id !== product.id
      );
    },

    onSetRegNumber(input) {
      this.regNr = input;
    },
    onSetMultipleRegNumbers(input) {
      this.hasMultipleRegNumbers = input;
    },

    async shareCart() {
      try {
        await CartApi.shareCart(
          this.shareToEmail,
          this.selectedPickup?.destinationSiteId
        );
        this.cartShared = true;
      } catch (e) {
        console.log(e);
      }
    },

    async onShareModalClose() {
      this.fetchCart();
      this.activeSendCartModal = false;
      this.cartShared = false;
    },

    sendGTMEvent(step, callback, option) {
      const products = this.cart.cart.lineItems.map((lineItem) => {
        const brandCustomData = lineItem.customData.find(
          (l) => l.name === LineItemCustomDataKeys.Brand
        );
        const variantCustomData = lineItem.customData.find(
          (l) => l.name === LineItemCustomDataKeys.Variant
        );
        return {
          name: lineItem.name,
          id: lineItem.productId,
          price: lineItem.price.current,
          brand: brandCustomData && brandCustomData.value,
          category: lineItem.productType,
          variant: variantCustomData && variantCustomData.value,
          dimension6: lineItem.productCondition === "New" ? "Ny" : "Begagnad",
          quantity: lineItem.quantity,
        };
      });

      const data = {
        checkout: {
          actionField: {
            step: step.order,
            option: option || step.string,
          },
          products: [...products],
        },
      };
      gtm.ecommerceEvent(GtmEvents.Checkout, data, callback);
    },

    ssnChange(input) {
      this.ssn = input;
    },

    async fetchUpsellProducts() {
      try {
        const res = await CartApi.fetchUpsellProducts();
        this.upsellProducts = res.data.filter(
          (p) => !this.cartProductIds.includes(p.id)
        );
      } catch (error) {
        console.log("upsell products fetch failed", error);
      }
    },

    async onSubmit() {
      $("#checkout-submit").addClass("disabled");
      const data = {
        customer: {
          ...this.fieldsExport,
        },
        payment: {
          paymentMethodId: this.cartSelectedPayment,
          ...this.paymentInputsExport,
        },
        pickup: this.selectedPickup,
        tyreHotelOrder: this.tyreHotelOrder,
        registrationNumber: this.regNr,
      };

      this.submitError = null;
      console.log(data);

      try {
        const res = await OrderApi.submit(data);

        console.log(res.data);
        if (this.isAccessoryCheckout) {
          Cookies.remove("AccessoryRegNr");
        }
        if (res.data.signingUrl) {
          window.location.href = res.data.signingUrl;
        }
      } catch (error) {
        console.log("submit failed", error);

        if (error.response && error.response.data) {
          this.submitError = error.response.data;
        } else {
          this.submitError = this.genericPaymentError;
        }
      }
    },
  },
};
</script>
