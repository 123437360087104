import FieldTypes from '../constants/FieldTypes';
import InputTypes from '../../shared/wizard/constants/InputTypes';
import SectionTypes from '../constants/SectionTypes';
import RegExPatterns from '../../../constants/RegExPatterns';

export default [
  {
    type: FieldTypes.CustomerIsWaiting,
    value: false,
  },
  {
    label: 'Verkstad',
    type: FieldTypes.DepartmentNr,
  },
  {
    label: 'Regnummer',
    type: FieldTypes.RegNr,
    inputPattern: RegExPatterns.RegistrationNumber,
  },
  {
    section: SectionTypes.Vehicle,
    type: FieldTypes.VehicleInfoRegNr,
    label: 'Registreringsnummer',
    hidden: true,
  },
  {
    section: SectionTypes.Vehicle,
    type: FieldTypes.VehicleName,
    label: 'Fordonsnamn',
    hidden: true,
  },
  {
    section: SectionTypes.Department,
    type: FieldTypes.DepartmentName,
    label: 'Anläggning',
    hidden: true,
  },
  {
    section: SectionTypes.TyreServices,
    label: 'Däcktjänster',
    type: FieldTypes.TyreServices,
    inputType: InputTypes.OptionBoxes,
    required: true,
    fullRow: true,
  },
  {
    section: SectionTypes.Addons,
    label: 'Tillval',
    type: FieldTypes.Addons,
    inputType: InputTypes.OptionBoxesMulti,
    options: [],
    required: false,
    nestedOption: true,
  },
  {
    section: SectionTypes.Date,
    label: 'Välj datum',
    shortLabel: 'Datum',
    placeholder: 'Välj datum',
    type: FieldTypes.Date,
    inputType: InputTypes.Date,
    required: true,
    options: [],
  },
  {
    section: SectionTypes.Date,
    label: 'Välj tid',
    shortLabel: 'Tid',
    placeholder: 'Välj tid',
    type: FieldTypes.Time,
    inputType: InputTypes.Time,
    required: true,
    options: [],
  },
  {
    section: SectionTypes.Contact,
    label: 'Namn',
    placeholder: 'Vad heter du?',
    type: FieldTypes.Name,
    inputType: InputTypes.Text,
    autocomplete: 'name',
    required: true,
  },
  {
    section: SectionTypes.Contact,
    label: 'E-post',
    placeholder: 'Ange din e-postadress',
    type: FieldTypes.Email,
    inputType: InputTypes.Email,
    inputPattern: RegExPatterns.Email,
    autocomplete: 'email',
    required: true,
  },
  {
    section: SectionTypes.Contact,
    label: 'Mobilnummer',
    placeholder: '070-123 45 67',
    type: FieldTypes.Phone,
    inputType: InputTypes.Phone,
    inputPattern: RegExPatterns.Phone,
    autocomplete: 'tel',
    required: true,
  },
]
