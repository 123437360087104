import mountApp from "../utils/mount-app";
import ReleasingBlock from "../apps/releasing-block";

export default {
  init() {
    this.modifyDOM();
  },

  modifyDOM() {
    mountApp({
      app: ReleasingBlock,
      selector: '[data-app=releasing-block]',
      // extractProps: el => ({
      //   locationId: el.getAttribute('data-location-id'),
      //   heading: el.getAttribute('data-heading'),
      //   address: el.getAttribute('data-address'),
      //   reviewsUrl: el.getAttribute('data-reviews-url'),
      // }),
    });
  },
}
