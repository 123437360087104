<template>
  <div>
    <div class="product-main-action__configurator">
      <ConfiguratorSection :rowTitle="financingSectionName" :isRowOpen="rowOpen === rowKeys.price" :rowKey="rowKeys.price" @onOpenRow="openRow">
        <div slot="row-value" class="product-main-action__row__value">
          <div
            class="price-tag"
            :class="{
              'price-tag--discount': priceDisplay.current < priceDisplay.ordinary
            }"
          >
            {{ $formatPriceNoSuffix(priceDisplay.current) + priceDisplay.suffix }}
          </div>
          <div
            v-if="priceDisplay.current < priceDisplay.ordinary"
            class="price-tag price-tag--obsolete"
          >
            {{ $formatPriceNoSuffix(priceDisplay.ordinary) + priceDisplay.suffix }}
          </div>
        </div>
        <div slot="row-content" class="product-main-action__row__content">
          <FinanceSection
            :selected="selectedFinancingOption"
            :financeOptions="financeOptions"
            @change-finance-option="onChangeFinanceOption"
            :lang="lang"
          />
        </div>
      </ConfiguratorSection>
      <ConfiguratorSection v-if="displayDepartments" rowTitle="Leverans" :isRowOpen="rowOpen === rowKeys.department" :rowKey="rowKeys.department" @onOpenRow="openRow">
        <div slot="row-value" class="product-main-action__row__value">
          <div
            v-if="selectedDepartmentOption"
            class="delivery-indicator"
            :class="{
              'delivery-indicator--available': selectedDepartmentOption.value === this.departments[0].value,
              'delivery-indicator--unavailable': selectedDepartmentOption.value !== this.departments[0].value,
            }"
          ></div>
          {{ selectedDepartmentOption ? selectedDepartmentOption.name : 'Välj anläggning' }}
        </div>
        <div slot="row-content" class="product-main-action__row__content">
          <ConfiguratorSubSelect
            :options="departments"
            @change="onChangeDepartment"
            :defaultValue="selectedDepartment"
            isDepartmentSelect
          />
        </div>
      </ConfiguratorSection>
      <ConfiguratorSection v-if="displayTradeIn" rowTitle="Inbytesbil" :isRowOpen="rowOpen === rowKeys.tradeIn" :rowKey="rowKeys.tradeIn" @onOpenRow="openRow">
        <div slot="row-value" class="product-main-action__row__value" :class="{ 'product-main-action__row__value--static': !appraisedVehicle }">
          {{ appraisedVehicle ? appraisedVehicle.toUpperCase() : 'Lägg till' }}
        </div>
        <div slot="row-content" class="product-main-action__row__content">
          <TradeInSection :lang="lang" :valuationSubmitted="valuationSubmitted" :currentVehicleRegNr="regNr" @onValuationSubmitted="onValuationSubmitted" @onVehicleAppraised="onVehicleAppraised" />
        </div>
      </ConfiguratorSection>
      <ConfiguratorSection v-if="displayServiceAgreement" rowTitle="Serviceavtal" :isRowOpen="rowOpen === rowKeys.serviceAgreement" :rowKey="rowKeys.serviceAgreement" @onOpenRow="openRow">
        <div slot="row-value" class="product-main-action__row__value" :class="{ 'product-main-action__row__value--static': !storeState.serviceAgreementSummation }">
          {{ storeState.serviceAgreementSummation ? `+${serviceAgreementTotal} kr/mån` : 'Lägg till' }}
        </div>
        <div slot="row-content" class="product-main-action__row__content">
          <ServiceAgreementSection :lang="lang" :serviceAgreementData="serviceAgreementData" />
        </div>
      </ConfiguratorSection>
      <ConfiguratorSection v-if="displayTyres" rowTitle="Vinterhjul" :isRowOpen="rowOpen === rowKeys.tyres" :rowKey="rowKeys.tyres" @onOpenRow="openRow">
        <div slot="row-value" class="product-main-action__row__value" :class="{ 'product-main-action__row__value--static': !this.selectedTyres.length > 0 }">
          {{ tyreValueString }}
        </div>
        <div slot="row-content" class="product-main-action__row__content">
          <TyreSection :lang="lang" :selectedFinancingOption="selectedFinancingOption" :displayTax="displayTax" />
        </div>
      </ConfiguratorSection>
      <ConfiguratorSection v-if="displayFittings || displayChargeBox" rowTitle="Tillbehör" :isRowOpen="rowOpen === rowKeys.accessories" :rowKey="rowKeys.accessories" @onOpenRow="openRow">
        <div slot="row-value" class="product-main-action__row__value" :class="{ 'product-main-action__row__value--static': true }">
          {{ addonsLength > 0 ? `${addonsLength} aktivt val` : 'Lägg till' }}
        </div>
        <div slot="row-content" class="product-main-action__row__content">
          <AccessoriesSection :lang="lang" />
        </div>
      </ConfiguratorSection>
    </div>
    <div class="product-main-action__summary">
      <SummationList
        :lang="lang"
        :selectedFinancing="selectedFinancingOption"
        :displayTax="displayTax"
        :inSummation="true"
      />
    </div>
  </div>
</template>

<script>
import FinanceOptions from '../../constants/FinanceOptions';
import store from './store';
import FinanceSection from './configurator-sections/FinanceSection.vue'
import ConfiguratorSubSelect from "./configurator-components/ConfiguratorSubSelect.vue";
import ConfiguratorSection from "./configurator-components/ConfiguratorSection.vue";
import rowKeys from './constants/row-keys';
import ConfiguratorTextInput from "./configurator-components/ConfiguratorTextInput.vue";
import TradeInSection from "./configurator-sections/TradeInSection.vue";
import ServiceAgreementSection from "./configurator-sections/ServiceAgreementSection.vue";
import SummationList from "./configurator-components/SummationList.vue";
import TyreSection from "./configurator-sections/TyreSection.vue";
import AccessoriesSection from "./configurator-sections/AccessoriesSection.vue";
import CalculationKeys from "./constants/calculation-keys";
import { monthlyCost, monthlyCostTyre } from "../../utils/priceCalculations";

export default {
  components: {
    FinanceSection,
    ConfiguratorSubSelect,
    ConfiguratorSection,
    ConfiguratorTextInput,
    TradeInSection,
    ServiceAgreementSection,
    SummationList,
    TyreSection,
    AccessoriesSection
},
  props: {
    lang: Object,
    regNr: String,
    priceDisplay: Object,
    displayTax: Boolean,
    selectedFinancingOption: Object,
    position: String,
  },
  data() {
    return {
      storeState: store.state,
      rowKeys,
      rowOpen: '',
      FinanceOptions,
      appraisedVehicle: '',
      valuationSubmitted: false,
    };
  },

  computed: {
    displayTyres() {
      return this.storeState.tyreData?.display && this.selectedFinancingOption.id !== FinanceOptions.PRIVATELEASING && this.selectedFinancingOption.id !== FinanceOptions.OPLEASING && this.selectedFinancingOption.id !== FinanceOptions.BLLEASING;
    },
    displayFittings() {
      return this.storeState.fittings?.display && this.selectedFinancingOption.id !== FinanceOptions.PRIVATELEASING && this.selectedFinancingOption.id !== FinanceOptions.OPLEASING && this.selectedFinancingOption.id !== FinanceOptions.BLLEASING;
    },
    displayTradeIn() {
      return this.storeState.tradeIn?.display && this.selectedFinancingOption.id !== FinanceOptions.PRIVATELEASING && this.selectedFinancingOption.id !== FinanceOptions.OPLEASING && this.selectedFinancingOption.id !== FinanceOptions.BLLEASING;
    },
    displayChargeBox() {
      return this.storeState.chargeBox?.display && this.selectedFinancingOption.id !== FinanceOptions.PRIVATELEASING && this.selectedFinancingOption.id !== FinanceOptions.OPLEASING && this.selectedFinancingOption.id !== FinanceOptions.BLLEASING;
    },
    displayDepartments() {
      return this.storeState.contact?.displayDepartments;
    },
    displayServiceAgreement() {
      return (
        this.selectedFinancingOption.id !== FinanceOptions.PRIVATELEASING &&
        this.selectedFinancingOption.id !== FinanceOptions.OPLEASING &&
        this.storeState.serviceAgreement?.display
      );
    },
    financingSectionName() {
      return `${this.selectedFinancingOption.name} ${this.selectedFinancingOption.nameSuffix}`
    },
    departments() {
      return this.storeState.departments;
    },
    selectedDepartment() {
      return store.state.selectedDepartment;
    },
    selectedDepartmentOption() {
      return this.departments.find(department => department.value === this.selectedDepartment);
    },
    financingConfig() {
      return !!this.storeState && !!this.storeState.financingConfig
        ? this.storeState.financingConfig
        : null;
    },
    financingSummary() {
      return !!this.storeState && !!this.storeState.financingSummary
        ? this.storeState.financingSummary
        : null;
    },
    financeOptions() {
      return !!this.storeState && !!this.storeState.financeOptions
        ? this.storeState.financeOptions
        : [];
    },
    serviceAgreementData() {
      return !!this.storeState.serviceAgreement
        ? this.storeState.serviceAgreement
        : null;
    },
    selectedTyres() {
      const tyres =
        this.storeState.tyres && this.storeState.tyres.length > 0
          ? this.storeState.tyres
          : [];

      return tyres.filter(tyre => tyre.selected);
    },
    addonsLength() {
      const list = []

      if (this.displayFittings) {
        const selectedFittings = this.storeState.fittingOptions.filter(
          fitting => fitting.selected
        );

        list.push(...selectedFittings);
      }

      const needChargeBox = this.storeState.needChargeBox;
      if (needChargeBox) {
        const needChargeBoxSummation = {
          name: "Vill ha prisförslag på laddbox",
          value: ""
        };
        list.push(needChargeBoxSummation);
      }

      return list.length;
    },
    serviceAgreementTotal() {
      if (this.storeState.serviceAgreementSummation) {
        const additionalProductsCost = this.storeState.serviceAgreementSummation.additionalProducts
          .filter(product => product.selected)
          .reduce((acc, curr) => acc + curr.monthlyCost, 0);
        return this.storeState.serviceAgreementSummation.monthlyCost + additionalProductsCost;
      }
    },
    tyreValueString() {
      const selectedTyres = this.storeState.tyres.filter(tyre => tyre.selected)
      const tyreCost = selectedTyres.reduce((acc, curr) => (
        this.selectedFinancingOption.calculationType === CalculationKeys.Monthly
          ? monthlyCostTyre(this.selectedFinancingOption.financing, curr.financing, this.displayTax ? curr.financing.currentPrice : curr.financing.currentPriceNoVat)
          : this.displayTax ? curr.financing.currentPrice : curr.financing.currentPriceNoVat
      ) + acc, 0).toFixed();

      const tyreTotal = {
        value: tyreCost,
        suffix: this.selectedFinancingOption.calculationType === CalculationKeys.Monthly
          ? " kr/mån"
          : " kr",
      }

      if (selectedTyres.length > 0) {
        return `+${tyreTotal.value} ${tyreTotal.suffix}`;
      } else if (this.storeState.needTyres) {
        return '1 aktivt val';
      } else {
        return 'Lägg till';
      }
    }
  },
  async created() {
    if (this.storeState.valuation) this.appraisedVehicle = this.storeState.valuation?.registrationNumber;
    if (this.position === 'vehiclepage')
    {
      this.rowOpen = rowKeys.price;
    }
    if ((this.storeState.tyreData?.display && this.selectedFinancingOption.id !== FinanceOptions.PRIVATELEASING && this.selectedFinancingOption.id !== FinanceOptions.OPLEASING && this.selectedFinancingOption.id !== FinanceOptions.BLLEASING))
    {
      this.rowOpen = rowKeys.tyres;
    }
  },
  methods: {
    openRow(key) {
      if (this.rowOpen === key) {
        this.rowOpen = '';
      } else {
        this.rowOpen = key;
      }
    },
    onChangeFinanceOption(selected) {
      this.$emit('onChangeFinanceOption', selected);
    },
    onChangeDepartment(department) {
      store.setSelectedDepartment(department.value);
    },
    onValuationSubmitted(isSubmitted) {
      this.valuationSubmitted = isSubmitted;
    },
    onVehicleAppraised(regNr) {
      this.appraisedVehicle = regNr;
    },
  }
};
</script>
