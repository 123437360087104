<template>
  <div class="u-margin-top">
    <div class="row">
      <div class="col-md-12">
        <h4 class="u-fw-medium">
          Ytterligare uppgifter<br />
          <small class="u-c-muted"
            >För att kunna ge dig ett så korrekt serviceavtal som möjligt
            behöver du fylla i följande uppgifter.</small
          >
        </h4>
        <div class="row">
          <div class="col-md-4">
            <VcFormStack label="Avtalsperiod" required>
              <VcFormSelect
                prompt="Välj avtalsperiodperiod"
                :options="months"
                :value="store.additionalInfo.months"
                valueProp="value"
                labelProp="label"
                valueType="number"
                required
                @change="onMonthsChange"
              >
              </VcFormSelect>
            </VcFormStack>
          </div>
          <div class="col-md-4">
            <VcFormStack label="Körsträcka (mil per år)" required>
              <VcFormSelect
                prompt="Välj körsträcka"
                :options="mileage"
                :value="store.additionalInfo.mileage"
                valueProp="value"
                labelProp="label"
                valueType="number"
                required
                @change="onMileageChange"
              >
              </VcFormSelect>
            </VcFormStack>
          </div>
          <div class="col-md-4">
            <VcFormStack label="Mätarställning (mil)" :required="true">
              <input
                v-model="store.additionalInfo.currentMileage"
                class="form-control"
                placeholder="Ange mätarställning i mil"
                :required="true"
                type="number"
              />
            </VcFormStack>
          </div>
          <div class="col-md-4 u-margin-top" v-if="store.hasPackages">
            <button
              v-if="!hasFetched"
              @click="fetchAgreement(true)"
              class="btn btn--primary btn--block"
              :disabled="!store.canFetchAgreement"
            >
              Hämta serviceavtal
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VcFormStack, VcFormSelect } from "@holmgrensbil/vue-components";
import store from "./store";
export default {
  components: {
    VcFormStack,
    VcFormSelect
  },
  data() {
    return {
      hasFetched: false,
      store: store.state
    };
  },
  watch: {
    "store.additionalInfo.currentMileage": function() {
      this.fetchAgreement();
    },
    "store.additionalInfo.months.value": function() {
      this.fetchAgreement();
    },
    "store.additionalInfo.mileage.value": function() {
      this.fetchAgreement();
    }
  },
  computed: {
    months() {
      return [12, 24, 36, 48, 60].map(n => {
        return { value: n, label: n.toString() + " månader" };
      });
    },
    mileage() {
      return [1000, 1500, 2000, 2500].map(n => {
        return { value: n, label: n.toString() + " mil" };
      });
    },
    disabled() {}
  },

  methods: {
    async fetchAgreement(btnClick = false) {
      if (!btnClick && !this.hasFetched) {
        return;
      }
      this.hasFetched = true;
      if (!this.store.canFetchAgreement) {
        return;
      }
      await store.fetchAgreement();
    },
    onMileageChange(e) {
      this.store.additionalInfo.mileage = e;
    },
    onMonthsChange(e) {
      this.store.additionalInfo.months = e;
    }
  }
};
</script>
