import axios from "axios";

export default {
  init() {
    this.addEvents();
  },

  addEvents() {
    $(document).on(
      "submit",
      "#my-vehicle-estimation",
      this.eventHandlers.onSubmit
    );
  },

  eventHandlers: {
    async onSubmit(e) {
      e.preventDefault();
      const res = await axios.post("/api/mypages/myvehicles/estimation", {
        vehicleId: $(this).data("vehicle-id"),
        estimation: $("#estimatedPrice").val(),
        email: $("#email").val(),
      });
      console.log(res);
      location.reload();
    }
  }
};
