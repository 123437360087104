<template>
  <div class="spare-parts-modal">
    <div class="spare-parts-form">
      <div class="form-step__header">
        <div class="form-step__title">
          Information om reservdelen
        </div>
        <div class="form-step__sub-title">
          Genom att att veta vilket fordon du har kan vi enklare hitta en
          reservdel
        </div>
      </div>
      <div class="form-row">
        <div class="form-stack reg-nr">
          <div class="reg-nr__field">
            <div class="input-group-prepend">
              <div
                class="regnr-input__symbol reg-nr__field__symbol reg-nr__field__symbol--rounded"
              ></div>
            </div>
            <div
              class="form-stack reg-nr__field__container"
              :class="{ 'form-stack--lg': true }"
            >
              <label>
                Regnummer
              </label>
              <input
                id="regNr-input"
                type="text"
                @keyup="onChangeRegNr()"
                class="form-control reg-nr__field__input"
                :class="{ 'form-control--invalid': regNrInvalid }"
                placeholder="ABC123"
                required
                pattern="^\w{3}\d{2}(\w{1}|\d{1})$"
                autocomplete="off"
                v-model="regNr"
              />
            </div>
          </div>
        </div>
        <div class="car-info" v-if="validCarInfo">
          <table class="car-info__table">
            <tr>
              <td class="car-info__table__header">MÄRKE</td>
              <td class="car-info__table__header">MODELL OCH VERSION</td>
              <td class="car-info__table__header">ÅRSMODELL</td>
            </tr>
            <tr>
              <td class="car-info__table__text">
                {{ carInfo.brand }}
              </td>
              <td class="car-info__table__text">
                {{ `${carInfo.model} ${carInfo.version}` }}
              </td>
              <td class="car-info__table__text">
                {{ carInfo.modelYear }}
              </td>
            </tr>
          </table>
        </div>
      </div>
      <label>
        Beskriv reservdelen Som är skadad eller defekt
      </label>
      <div class="form-row">
        <div class="form-row__col">
          <div class="form-stack form-stack--required">
            <label>
              Beskrivning av reservdelen
            </label>
            <textarea
              placeholder="Beskriv här..."
              :class="{ 'form-control--invalid': messageInvalid }"
              required="required"
              class="form-control"
              style="margin: 0px 6px 0px 0px; height: 118px; width: 100%;"
              v-model="message"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="field-input-images">
          <label>
            Ladda upp bilder på reservdelen
          </label>
          <div class="field-input-images__grid">
            <ImageUpload
              v-for="imageComponent in imageComponentArray"
              :key="imageComponent"
              :i="imageComponent"
              :regNr="regNr"
              @onChangeImage="changeImage"
              @onDeleteImage="deleteImage"
              @onError="showError"
            />
          </div>
          <div class="feedback-message feedback-message--danger" v-if="error">
            <div class="feedback-message__text">
              Du måste ange ett registrerings nummer för att kunna ladda upp
              bilder
            </div>
          </div>
        </div>
      </div>
      <div class="form-step__header">
        <div class="form-step__title">
          Dina uppgifter
        </div>
        <div class="form-step__sub-title">
          Skriv in dina uppgifter här
        </div>
      </div>
      <div class="form-stack form-stack--required">
        <label>
          NAMN
        </label>
        <input
          class="form-control"
          :class="{ 'form-control--invalid': customerNameInvalid }"
          v-model="customerName"
          autocomplete="name"
          placeholder="Vad heter du?"
          type="text"
          required
        />
      </div>
      <div class="form-row">
        <div class="form-row__col form-row__col--md-50">
          <div class="form-stack form-stack--required">
            <label>
              E-POST
            </label>
            <input
              class="form-control"
              :class="{ 'form-control--invalid': customerEmailInvalid }"
              v-model="customerEmail"
              autocomplete="email"
              placeholder="Ange din e-postadress"
              type="email"
              required
            />
          </div>
        </div>
        <div class="form-row__col form-row__col--md-50">
          <div class="form-stack form-stack--required">
            <label>
              MOBILNUMMER
            </label>
            <input
              class="form-control"
              :class="{ 'form-control--invalid': customerPhoneInvalid }"
              v-model="customerPhone"
              autocomplete="tel"
              placeholder="Ange ditt telefonnummer"
              type="tel"
              required
            />
          </div>
        </div>
      </div>
      <button
        type="submit"
        @click="onSubmit"
        class="btn btn--primary btn--block"
      >
        Skicka förfrågan
      </button>
      <p class="form-text" v-html="lang.General.PrivacyPolicyText"></p>
    </div>
    <div class="form-feedback u-d-none">
      <div class="loader loader--success">
        <div class="loader__shape">
          <div class="loader__symbol"></div>
        </div>
        <div class="loader__header">
          Tack för din förfrågan
        </div>
        <div class="loader__text">
          Vi kommer kontakta dig om din reservdel inom kort.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionField from "../shared/wizard/SectionField.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import ImageUpload from "./ImageUpload.vue";
import ServicesApi from "../../models/ServicesApi";
import Axios from "../../axios";
import gtm from "../../utils/gtm";
const regNrRegexp = /^\w{3}\d{2}(\w{1}|\d{1})$/;
const imageComponentArray = [0, 1, 2, 3, 4];

export default {
  components: {
    SectionField,
    FontAwesomeIcon,
    ImageUpload
  },
  props: {
    departmentNr: Number,
    lang: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      uploading: false,
      regNr: "",
      carInfo: null,
      error: false,
      imageComponentArray,
      images: {
        0: "",
        1: "",
        2: "",
        3: "",
        4: ""
      },
      message: "",
      customerName: "",
      customerEmail: "",
      customerPhone: "",
      regNrInvalid: false,
      messageInvalid: false,
      customerNameInvalid: false,
      customerEmailInvalid: false,
      customerPhoneInvalid: false
    };
  },
  computed: {
    validRegNr() {
      return regNrRegexp.test(this.regNr);
    },
    validCarInfo() {
      return this.carInfo && this.carInfo.registerNumber;
    }
  },
  watch: {
    regNr() {
      this.regNrInvalid = false;
    },
    message() {
      this.messageInvalid = false;
    },
    customerName() {
      this.customerNameInvalid = false;
    },
    customerEmail() {
      this.customerEmailInvalid = false;
    },
    customerPhone() {
      this.customerPhoneInvalid = false;
    }
  },
  methods: {
    async onChangeRegNr() {
      console.log("test");

      if (this.regNr.length > 6) {
        this.regNr = this.regNr
          .split("")
          .slice(0, 6)
          .join("");
        return;
      }

      if (this.validRegNr) {
        const res = await ServicesApi.fetchCarInfo({
          regNr: this.regNr
        });
        this.hideError();
        this.carInfo = res.data;
      }
    },
    showError() {
      this.error = true;
    },
    hideError() {
      this.error = false;
    },
    changeImage(image) {
      this.images = { ...this.images, ...image };
    },
    deleteImage(index) {
      this.images = { ...this.images, [index]: "" };
    },
    async onSubmit() {
      if (!this.validRegNr) this.regNrInvalid = true;
      if (!this.message) this.messageInvalid = true;
      if (!this.customerName) this.customerNameInvalid = true;
      if (!this.customerEmail) this.customerEmailInvalid = true;
      if (!this.customerPhone) this.customerPhoneInvalid = true;
      if (
        !this.validRegNr ||
        !this.message ||
        !this.customerName ||
        !this.customerEmail ||
        !this.customerPhone
      ) {
        return;
      }

      const requestBody = {
        registrationNumber: this.regNr,
        departmentNr: this.departmentNr,
        message: this.message,
        customerName: this.customerName,
        customerEmail: this.customerEmail,
        customerPhone: this.customerPhone,
        image1: this.images[0],
        image2: this.images[1],
        image3: this.images[2],
        image4: this.images[3],
        image5: this.images[4]
      };

      Axios.post("/api/Contact/SendSparePartsRequest", requestBody)
        .then(() => {
          gtm.sparePartsFormSubmit({
            departmentNr: this.departmentNr,
            brandName: this.carInfo.brand,
            formType: "SparePartForm"
          });
          $(".spare-parts-modal .form-feedback").removeClass("u-d-none");
          $(".spare-parts-modal .spare-parts-form").addClass("u-d-none");
        })
        .catch(() => {
          $(".spare-parts-modal .spare-parts-form").removeClass("u-d-none");
        });
      // const res = await Axios.post('/api/Contact/SendSparePartsRequest', requestBody)
    }
  }
};
</script>
