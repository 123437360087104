<template>
  <VcFormStep
    :completed="completed || valuationComplete"
    :disabled="disabled"
    :feedbackDanger="displaySectionValidationError"
    :arrow="!displaySectionValidationError"
    :title="getTitle(section)"
    :subtitle="getSubtitle(section)"
    :summary="section.summary"
  >
    <SectionEditButton
      slot="header-aside"
      v-if="(completed || valuationComplete) && !submitted"
      @click="onEdit"
    >
    </SectionEditButton>
    <div slot="content">
      <div v-if="valuationComplete">
        <FieldChecklist :fields="sectionFields"> </FieldChecklist>
        <div class="vehicle-history-forms__request-info__result">
          <div class="main-info__heading">
            Estimerad värdering
          </div>
          <div class="main-info__data">
            ca. {{ $formatPrice(fetchedValuation.price.prediction) }}*
          </div>
          <div class="main-info__meta">
            *Preliminärt pris från inbytesbil. Du kommer få ett exakt pris när
            vårt värderingsteam värderar din inbytesbil.
          </div>
        </div>
      </div>
      <div v-else-if="active || section.summary">
        <component
          :is="SectionTypeComponents[section.type]"
          :section="section"
          :sectionValid="valid"
          @submit="onSubmit"
        >
        </component>
      </div>
    </div>
    <div v-if="active && !hideFooter" slot="footer">
      <div v-if="valuationComplete" class="valuation-confirmation-container">
        <div class="form-step__header">
          <div>
            <div class="form-step__title">
              Gå vidare för att få värderingen bekräftad
            </div>
            <div class="form-step__subtitle">
              Estimerade priset är endast en uppskattning. Gå vidare för att få
              värdering validerade av vårt värderingsteam.
            </div>
          </div>
        </div>
        <a class="btn btn--block btn--primary" @click="onNext">
          Bekräfta värdering
        </a>
      </div>
      <div v-else>
        <a
          class="btn btn--block btn--primary"
          :class="{
            disabled: loading
          }"
          @click="onSubmit"
        >
          Värdera gratis nu
          <FontAwesomeIcon v-if="loading" :icon="faSpinnerThird" :spin="true">
          </FontAwesomeIcon>
        </a>
      </div>
    </div>
    <SectionValidationError
      v-if="displaySectionValidationError"
      slot="feedback"
      :fields="invalidSectionFields"
    >
    </SectionValidationError>
  </VcFormStep>
</template>

<script>
import { VcFormStep } from "@holmgrensbil/vue-components";
import { mapState, mapGetters, mapActions } from "vuex";
import SectionValidationError from "./SectionValidationError.vue";
import FieldChecklist from "./FieldChecklist.vue";
import SectionEditButton from "./SectionEditButton.vue";
import RequestStates from "../../../constants/RequestStates";
import FieldTypes from "../../trade-in/constants/FieldTypes";
import Axios from "../../../axios";
import { faSpinnerThird } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import gtm from "../../../utils/gtm";

export default {
  components: {
    VcFormStep,
    SectionValidationError,
    SectionEditButton,
    FieldChecklist,
    FontAwesomeIcon
  },
  props: {
    section: Object,
    footerLabel: String,
    hideFooter: Boolean,
    SectionTypeComponents: Object
  },
  data() {
    return {
      fetchedValuation: null,
      loading: false,
      faSpinnerThird,
      valuationComplete: false
    };
  },
  computed: {
    ...mapState({
      lang: state => state.config.lang,
      order: state => state.sections.order,
      formSubmitState: state => state.form.submitState
    }),

    ...mapGetters({
      fieldsBySection: "fields/bySection",
      invalidFieldsBySection: "fields/invalidBySection",
      fieldByType: "fields/byType"
    }),

    submitted() {
      return this.formSubmitState === RequestStates.Success;
    },

    active() {
      return this.section.order === this.order;
    },

    completed() {
      return this.section.order < this.order;
    },

    disabled() {
      return this.section.order > this.order;
    },

    sectionFields() {
      const vehicleData = this.fetchedValuation
        ? [
            { value: this.fetchedValuation.dataUsed.manufacturer, label: "" },
            {
              value: `${this.fetchedValuation.dataUsed.modelSeries} ${this.fetchedValuation.dataUsed.modelName}`,
              label: ""
            }
          ]
        : [];
      const fields = [
        ...vehicleData,
        ...this.fieldsBySection(this.section.type).map(field => {
          return {
            ...field,
            label: "",
            shortLabel: ""
          };
        })
      ];
      return fields;
    },

    invalidSectionFields() {
      return this.invalidFieldsBySection(this.section.type);
    },

    displaySectionValidationError() {
      return this.section.validate && !!this.invalidSectionFields.length;
    },

    valid() {
      return !this.invalidSectionFields.length;
    }
  },

  methods: {
    ...mapActions({
      validateSection: "sections/validate",
      goToSection: "sections/goTo",
      fetchLookup: "lookup/fetch"
    }),

    onEdit() {
      this.valuationComplete = false;
      this.goToSection(this.section.order);
    },

    async onSubmit() {
      this.validateSection({ section: this.section });

      if (this.valid) {
        try {
          const regNr = this.fieldByType(FieldTypes.RegNr).value;
          const mileage = this.fieldByType(FieldTypes.Mileage).value;
          const condition = this.fieldByType(FieldTypes.VehicleCondition)
            .value[0].name;
          const email = this.fieldByType(FieldTypes.Email).value;
          this.loading = true;
          const { data } = await Axios.get(
            `/api/vehicle/tradeIn?registrationNumber=${regNr}&mileage=${mileage}&condition=${condition}&email=${email}`
          );
          this.fetchedValuation = data;
          await this.fetchLookup(regNr);
          this.valuationComplete = true;

          gtm.tradeInRegnrSectionCompleted();
          return true;
        } catch (e) {
          console.error(e);
        } finally {
          this.loading = false;
        }
      }

      return false;
    },
    onNext() {
      this.$emit("done");
    },

    getTitle(section) {
      console.log(section.type, this.lang.App.Sections);
      const obj =
        this.lang &&
        this.lang.App &&
        this.lang.App.Sections &&
        this.lang.App.Sections[section.type];
      return obj && obj.Title;
    },

    getSubtitle(section) {
      const obj =
        this.lang &&
        this.lang.App &&
        this.lang.App.Sections &&
        this.lang.App.Sections[section.type];
      return obj && obj.Subtitle;
    }
  }
};
</script>
