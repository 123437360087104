import CalculationKeys from "../apps/main-action/constants/calculation-keys";

export const monthlyCost = (financing, price) => {
  const financingPrice = price || financing.currentPrice
  const selectedPeriod = financing.periods?.find(p => p.selected);

  return pmt(financingPrice, financing.rate, selectedPeriod?.months || 12, financing.selectedDownPayment, financing.residualValue, selectedPeriod?.residualPrecentage, financing.dueDate)
}

export const monthlyCostTyre = (financing, tyreFinancing, price) => {
  const financingPrice = price || tyreFinancing.currentPrice
  const selectedPeriod = financing.periods?.find(p => p.selected);

  return pmt(financingPrice, financing.rate, selectedPeriod?.months || 12, financing.selectedDownPayment, 0, tyreFinancing.residualPercentage, financing.dueDate)
}

export const pmt = (financingPrice, financingRate, nperiod, downPayment, residualValue, residualPercentage = 0, dueDate) => {
  // PMT Calculation
  let rate = financingRate / 100 / 12;
  let pv = -(financingPrice - (financingPrice * (downPayment / 100)));
  let fv = residualValue !== 0 ? residualValue : financingPrice * ((residualPercentage || 0) / 100);
  let type = dueDate;

  if (!fv) fv = 0;
  if (!type) type = 0;

  if (rate == 0) return -(pv + fv)/nperiod;

  var pvif = Math.pow(1 + rate, nperiod);
  var pmt = rate / (pvif - 1) * -(pv * pvif + fv);

  if (type == 1) {
    pmt /= (1 + rate);
  }

  return pmt;
}

export const getEffectiveRate = (financing, additionalMonthlyCost, additionalResidualValue, additionalTotalCost) => {
  const selectedPeriod = financing.periods?.find(p => p.selected)
  const paymentsPerYear = selectedPeriod?.months || 12;
  const paymentAmount = monthlyCost(financing) + (financing.arrangementFee / paymentsPerYear) + financing.administrationFee + additionalMonthlyCost;

  let presentValue = -(financing.currentPrice - ((financing.currentPrice + additionalTotalCost) * (financing.selectedDownPayment / 100)));
  const residualValue = financing.residualValue;
  let futureValue = (residualValue !== 0 ? residualValue : financing.currentPrice * ((selectedPeriod?.residualPrecentage || 0) / 100)) + additionalResidualValue;

  let dueEndOrBeginning = financing.dueDate;
  let interest = financing.rate / 100;

  if (interest == null)
    interest = 0.01;

  if (futureValue == null)
    futureValue = 0;

  if (dueEndOrBeginning == null)
    dueEndOrBeginning = 0;

  var FINANCIAL_MAX_ITERATIONS = 128;//Bet accuracy with 128
  var FINANCIAL_PRECISION = 0.0000001;//1.0e-8

  var y, y0, y1, x0, x1 = 0, f = 0, i = 0;
  var rate = interest;
  if (Math.abs(rate) < FINANCIAL_PRECISION)
  {
    y = presentValue * (1 + paymentsPerYear * rate) + paymentAmount * (1 + rate * dueEndOrBeginning) * paymentsPerYear + futureValue;
  }
  else
  {
    f = Math.exp(paymentsPerYear * Math.log(1 + rate));
    y = presentValue * f + paymentAmount * (1 / rate + dueEndOrBeginning) * (f - 1) + futureValue;
  }
  y0 = presentValue + paymentAmount * paymentsPerYear + futureValue;
  y1 = presentValue * f + paymentAmount * (1 / rate + dueEndOrBeginning) * (f - 1) + futureValue;

  // find root by Newton secant method
  i = x0 = 0.0;
  x1 = rate;
  while ((Math.abs(y0 - y1) > FINANCIAL_PRECISION)
  && (i < FINANCIAL_MAX_ITERATIONS))
  {
    rate = (y1 * x0 - y0 * x1) / (y1 - y0);
    x0 = x1;
    x1 = rate;

    if (Math.abs(rate) < FINANCIAL_PRECISION)
    {
      y = presentValue * (1 + paymentsPerYear * rate) + paymentAmount * (1 + rate * dueEndOrBeginning) * paymentsPerYear + futureValue;
    }
    else
    {
      f = Math.exp(paymentsPerYear * Math.log(1 + rate));
      y = presentValue * f + paymentAmount * (1 / rate + dueEndOrBeginning) * (f - 1) + futureValue;
    }

    y0 = y1;
    y1 = y;
    ++i;
  }

  return rate * 12 * 100;
}

export const getPrice = (financing, calculationType) => {
  return {
    current: calculationType === CalculationKeys.Set || calculationType === CalculationKeys.SetMonthly ? financing.currentPrice : monthlyCost(financing).toFixed(),
    ordinary: calculationType === CalculationKeys.Set || calculationType === CalculationKeys.SetMonthly ? financing.ordinaryPrice : monthlyCost(financing, financing.ordinaryPrice).toFixed(),
    suffix: calculationType === CalculationKeys.Set  ? ' kr' : ' kr/mån',
  }
}
