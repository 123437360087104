import mountApp from "../utils/mount-app";
import I3FilterTabs from "../apps/i3-filter-tabs";
import I3SearchWatch from "../apps/i3-search-watch";
import I3Result from "../apps/i3-result";
import Contact from "../apps/contact";
import QuickContact from "../apps/quick-contact";
import VehicleListApp from "../apps/vehicle-list/App.vue";
import AccessoriesListApp from "../apps/accessories-list/App.vue";
import CampaignListApp from "../apps/campaign-list/App.vue";
import VehicleSliderApp from "../apps/vehicle-slider/App.vue";
import BlogListApp from "../apps/blog-list/App.vue";
import EmployeeListApp from "../apps/employee-list/App.vue";
import ServiceBookingSelectorApp from "../apps/service-booking-selector";
import EonProductSlider from "../apps/eon-product-slider";
import ChooseSpec from "../apps/choose-spec";
import ChooseSpecMulti from "../apps/choose-spec-multi";
import VehicleSelector from "../apps/vehicle-selector";
import ServiceAgreementBlock from "../apps/service-agreement-block";
import TyreSpec from "../models/TyreSpec";

export default {
  init() {
    this.mountI3FilterTabs();
    this.mountI3SearchWatch();
    this.mountI3Result();
    this.mountEonProductSlider();
    this.mountServiceBookingSelectorApp();
    this.mountChooseSpecApp();
    this.mountVehicleSelectorApp();
    this.mountContactApp();
    this.mountServiceAgreementApp();
    this.addEvents();
    this.mountI3SearchWatch();
    this.mountChooseSpecMultiApp();
    //this.mountChat();
  },

  addEvents() {
    $("[data-quick-contact-modal]").on(
      "show.wdk.modal",
      this.onQuickContactModalShow
    );
    $("[data-quick-contact-modal]").on(
      "hide.wdk.modal",
      this.onQuickContactModalHide
    );
    $("[data-quick-contact-modal]").on(
      "shown.wdk.modal",
      this.onQuickContactModalShown
    );
  },

  // mountChat() {
  //   let initChat = JSON.parse(sessionStorage.getItem("initChat"));
  //   if (initChat) {
  //     var e, n;
  //     e = document.createElement("script");
  //     e.type = "text/javascript";
  //     e.src = "https://app.weply.chat/widget/681f2f9ba44d10e6b3adc9d0566937c4";
  //     e.async = true;
  //     n = document.getElementsByTagName("script")[0];
  //     n.parentNode.insertBefore(e, n);
  //   } else {
  //     $(".floating-action-btn").css("display", "flex");
  //   }
  // },

  onQuickContactModalShow() {
    $(".floating-action-btn__icon-closed").hide();
    $(".floating-action-btn__icon-open").show();
    mountApp({
      app: QuickContact,
      selector: "[data-quick-contact-modal] [data-app=quick-contact]",
      props: {
        pageName: window.document.title
      },
      extractProps: el => ({
        contentId: el.getAttribute("data-content-id"),
        serviceTag: el.getAttribute("data-service-tag"),
        locationTag: el.getAttribute("data-location-tag")
      })
    });
  },

  onQuickContactModalShown() {
    $("body").removeClass("modal-open");
  },

  onQuickContactModalHide() {
    $(".floating-action-btn__icon-closed").show();
    $(".floating-action-btn__icon-open").hide();
  },

  mountServiceAgreementApp() {
    mountApp({
      app: ServiceAgreementBlock,
      selector: "[data-app=service-agreement-block]"
    });
  },

  mountContactApp() {
    mountApp({
      app: Contact,
      selector: ".site-content [data-app=contact]",
      extractProps: el => ({
        sectionHandle: el.getAttribute("data-section-handle"),
        hideSections: el.getAttribute("data-hide-sections") === "true",
        preselectedServices: JSON.parse(
          el.getAttribute("data-preselected-services")
        ),
        preselectedBrand: el.getAttribute("data-preselected-brand")
      })
    });
  },

  mountChooseSpecApp() {
    mountApp({
      app: ChooseSpec,
      selector: "[data-app=choose-spec]",
      props: {
        TyreSpec
      },
      extractProps: el => ({
        header: el.getAttribute("data-header"),
        meta: el.getAttribute("data-meta"),
        promo: el.getAttribute("data-promo") !== null,
        promoBg: el.getAttribute("data-promo-bg"),
        buttonText: el.getAttribute("data-button-text"),
        buttonHref: el.getAttribute("data-button-href"),
        productTypeValue: el.getAttribute('data-product-type')
      })
    });
  },

  mountChooseSpecMultiApp() {
    mountApp({
      app: ChooseSpecMulti,
      selector: "[data-app=choose-spec-multi]",
      props: {
        TyreSpec
      },
      extractProps: el => ({
        header: el.getAttribute("data-header"),
        meta: el.getAttribute("data-meta"),
        promo: el.getAttribute("data-promo") !== null,
        promoBg: el.getAttribute("data-promo-bg"),
        tyreButtonHref: el.getAttribute("data-tyre-button-href"),
        rimButtonHref: el.getAttribute("data-rim-button-href"),
        wheelButtonHref: el.getAttribute("data-wheel-button-href"),
        productTypeValue: el.getAttribute('data-product-type')
      })
    });
  },

  mountVehicleSelectorApp() {
    mountApp({
      app: VehicleSelector,
      selector: '[data-app=vehicle-selector]',
      setI3Channel: true,
    });
  },

  mountServiceBookingSelectorApp() {
    mountApp({
      app: ServiceBookingSelectorApp,
      selector: "[data-app=service-booking-selector]",
      extractProps: el => ({
        preselectedService: el.getAttribute("data-preselected-service"),
        preselectedDepartment: el.getAttribute("data-departmentnr"),
        startMessage: el.getAttribute("data-startmessage"),
        fromSpecificServicePage: Boolean(
          el.getAttribute("data-from-specific-service-page")
        )
      })
    });
  },

  mountI3FilterTabs() {
    mountApp({
      app: I3FilterTabs,
      selector: "[data-app=i3-filter-tabs]",
      setI3Channel: true,
      extractProps: el => ({
        filters: JSON.parse(el.getAttribute("data-tab-filters"))
      })
    });
  },

  mountI3SearchWatch() {
    mountApp({
      app: I3SearchWatch,
      selector: "[data-app=i3-search-watch]",
      setI3Channel: true
    });
  },

  mountI3Result() {
    mountApp({
      app: I3Result,
      selector: "[data-app=i3-result]",
      setI3Channel: true,
      extractProps: el => ({
        RenderComponent: this.getResultAppRenderComponent(
          el.getAttribute("data-result-app")
        )
      })
    });
  },
  mountEonProductSlider() {
    mountApp({
      app: EonProductSlider,
      selector: "[data-app=eon-product-slider]",
      extractProps: el => ({
        typeId: parseInt(el.getAttribute("data-type-id"), 10),
        regNr: el.getAttribute("data-reg-nr"),
        title: el.getAttribute("data-title"),
        link: el.getAttribute("data-link"),
        linkTitle: el.getAttribute("data-link-title")
      })
    });
  },

  getResultAppRenderComponent(resultApp) {
    switch (resultApp) {
      case 'accessories-list':
        return AccessoriesListApp;
      case "vehicle-list":
        return VehicleListApp;
      case "campaign-list":
        return CampaignListApp;
      case "vehicle-slider":
        return VehicleSliderApp;
      case "blog-list":
        return BlogListApp;
      case "employee-list":
        return EmployeeListApp;
    }
  }
};
