<template>
  <div
    class="choose-spec choose-spec--multi"
    :class="{ 'choose-spec--promo': promo }"
    :style="{ backgroundImage: promoBg ? 'url(' + promoBg + ')' : 'none' }"
  >
    <div class="choose-spec__content">
      <div>
        <div class="promo__meta" v-if="meta" v-html="meta"></div>
        <h1 class="promo__header" v-if="header">
          {{ header }}
        </h1>
      </div>
      <div
        class="form-step"
        :class="{
          'form-step--completed': vehicle,
          'form-step--danger': regNrError,
        }"
      >

        <form @submit.prevent="onSubmit">
          <div class="form-row choose-spec__row">
            <div class="regnr-input">
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="regnr-input__symbol"></div>
                </div>
                <div class="form-stack">
                  <label>
                    Regnummer
                  </label>
                  <input
                    id="regNr-input"
                    type="text"
                    @keyup="onChange()"
                    class="form-control"
                    placeholder="ABC123"
                    style="color: #18191a"
                    required
                    pattern="^\w{3}\d{2}(\w{1}|\d{1})$"
                    v-model="regNr"
                  />
                </div>
              </div>
            </div>
            <div
              v-if="isMobile"
              class="choose-spec__options"
              :class="{
                'choose-spec__options--standard': !vehicle && !regNrError,
                'choose-spec__options--success': vehicle,
                'choose-spec__options--error': regNrError,
              }"
            >
              <div class="choose-spec__options-header">
                <span v-if="vehicle">{{ vehicleString }}</span>
                <span v-else-if="regNrError">{{ regNrError }}</span>
                <span v-else>Har du inget regnummer?</span>
              </div>
              <div class="inline-bullets">
                <div
                  v-if="!vehicle"
                  class="inline-bullets__item"
                  @click="$emit('chooseVehicleClick')"
                >
                  <a class="link link--underline">Välj bilmodell</a>
                </div>
                <div
                  class="inline-bullets__item"
                  @click="$emit('chooseTyreDimensionsClick')"
                >
                  <a class="link link--underline">Ange däckdimensioner</a>
                </div>
                <div
                  v-if="vehicle"
                  class="inline-bullets__item"
                  @click="clearVehicle"
                >
                  <a class="link link--underline">Byt fordon</a>
                </div>
              </div>
            </div>
            <div class="choose-spec__action-container">
              <VcFormStack label="Produkt">
                <VcFormSelect
                  :options="[{ value: 'tyre', label: lang.Tyre.TyreLinkBtn }, { value: 'wheel', label: lang.Tyre.WheelLinkBtn }, { value: 'rim', label: lang.Tyre.RimLinkBtn }]"
                  :value="productType"
                  labelProp="label"
                  valueProp="value"
                  valueType="string"
                  required
                  @change="productType = $event">
                </VcFormSelect>
              </VcFormStack>
              <VcFormStack label="Säsong">
                <VcFormSelect
                  :options="[{ value: EonTyreTypes.Summer, label: 'Sommar' }, { value: EonTyreTypes.WinterStudded, label: 'Vinter dubb' }, { value: EonTyreTypes.WinterFriction, label: 'Vinter friktion' }]"
                  :value="tyreType"
                  labelProp="label"
                  valueProp="value"
                  valueType="number"
                  required
                  @change="tyreType = $event">
                </VcFormSelect>
              </VcFormStack>

              <button
                class="btn btn--primary"
                :class="{
                  'disabled': !vehicle,
                }"
                @click="onSubmit"
              >
                {{ "Se produkter" }}
              </button>
            </div>
          </div>
        </form>
        <div
          v-if="!isMobile"
          class="choose-spec__options"
          :class="{
            'choose-spec__options--standard': !vehicle && !regNrError,
            'choose-spec__options--success': vehicle,
            'choose-spec__options--error': regNrError,
          }"
        >
          <div class="choose-spec__options-header">
            <span v-if="vehicle">{{ vehicleString }}</span>
            <span v-else-if="regNrError">{{ regNrError }}</span>
            <span v-else>Har du inget regnummer?</span>
          </div>
          <div class="inline-bullets">
            <div
              v-if="!vehicle"
              class="inline-bullets__item"
              @click="$emit('chooseVehicleClick')"
            >
              <a class="link link--underline">Välj bilmodell</a>
            </div>
            <div
              class="inline-bullets__item"
              @click="$emit('chooseTyreDimensionsClick')"
            >
              <a class="link link--underline">Ange däckdimensioner</a>
            </div>
            <div
              v-if="vehicle"
              class="inline-bullets__item"
              @click="clearVehicle"
            >
              <a class="link link--underline">Byt fordon</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VcFeedbackMessage, VcFormSelect, VcFormStack } from "@holmgrensbil/vue-components";
import Breakpoints from "../../constants/Breakpoints";
import EonApi from "../../models/EonApi";
import EonTyreTypes from "../eon-filter-page/constants/EonTyreTypes";

export default {
  components: {
    VcFeedbackMessage,
    VcFormSelect,
    VcFormStack,
  },
  props: {
    header: String,
    meta: String,
    promo: Boolean,
    promoBg: String,
    carLookupError: Boolean,
    preselectedRegNr: String,
    productTypeValue: String,
    lang: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      regNr: null,
      modalOpen: null,
      productType: { value: this.productTypeValue, label: this.lang.Tyre.TyreLinkBtn },
      tyreType: { value: EonTyreTypes.Summer, label: "Sommar" },
      vehicle: null,
      regNrError: '',
      EonTyreTypes
    };
  },

  computed: {
    largeRegnr() {
      return this.promo && this.windowWidth >= Breakpoints.md;
    },
    vehicleString() {
      return `${this.vehicle.brand?.name} ${this.vehicle.model?.name} · ${this.vehicle.tyreStandardSize?.diameter} ${this.vehicle.tyreStandardSize?.text}`;
    },
    isMobile() {
      return this.windowWidth < Breakpoints.md;
    },
  },
  mounted() {
    this.tyreType = window.EonConfig.isWinterSeason
      ? { value: EonTyreTypes.WinterFriction, label: 'Vinter friktion' }
      : { value: EonTyreTypes.Summer, label: 'Sommar' };
  },
  methods: {
    async onChange() {
      const regNr = this.regNr ? this.regNr.toUpperCase() : null;

      if (regNr) {
        if (!regNr.match(/^\w{3}\d{2}(\w{1}|\d{1})$/g)) {
          return;
        }

        const res = await EonApi.getCar(regNr);

        if (res.err || !res.data?.brand?.name || !res.data?.model?.name) {
          this.vehicle = null;
          this.regNrError = `Tyvärr fick vi ingen träff på ditt regnr ${regNr}`;
        } else {
          this.vehicle = res.data;
          this.regNrError = '';
        }

        this.regNr = regNr;

        this.$emit("change", { vehicle: res.data, regNr });
      }
    },
    onSubmit(e) {
      e.preventDefault();
      this.$emit("submit", this.productType.value, this.tyreType.value, this.vehicle);
    },
    clearVehicle() {
      this.regNr = '';
      this.vehicle = null;
    },
  }
};
</script>
